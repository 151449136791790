import how1 from "../assets/how/how1.webp";
import how2 from "../assets/how/how2.webp";
import how3 from "../assets/how/how3.webp";
import how4 from "../assets/how/how4.webp";
import how5 from "../assets/how/how5.webp";

import { BgH2 } from "./BgH2";
import { Space } from "./Space";

interface Step {
  title: string;
  image: string;
  alt: string;
  description: JSX.Element;
}

const steps: Step[] = [
  {
    title: "①登録・ログイン",
    image: how1,
    alt: "登録・ログイン",
    description: (
      <>
        メールアドレスまたはGoogleアカウントで
        <br />
        登録・ログインしよう！
      </>
    ),
  },
  {
    title: "②くじを引く",
    image: how2,
    alt: "くじを引く",
    description: (
      <>
        好きなくじと回数を選び、くじを購入しよう！
        <br />
        決済方法は商品ページをご確認ください。
      </>
    ),
  },
  {
    title: "③デジタル商品をゲット！",
    image: how3,
    alt: "デジタル商品をゲット!",
    description: (
      <>
        動くデジタル原画をゲットできます！
        <br />
        縦スクロールでパラパラ動かして楽しもう！
      </>
    ),
  },
  {
    title: "④配送依頼する",
    image: how4,
    alt: "配送依頼する",
    description: (
      <>
        配送管理ページから配送依頼をすると、
        <br />
        当たったカットのリアル商品が配送されます！
        <br />
        送料・梱包費は別途必要です。
      </>
    ),
  },
  {
    title: "⑤リアル商品を受け取る",
    image: how5,
    alt: "リアル商品を受け取る",
    description: (
      <>
        登録した配送先にリアル商品が届きます！
        <br />
        配送時期は配送管理ページをご確認ください。
      </>
    ),
  },
];

export const How = () => {
  return (
    <>
      <Space height={40} />
      <BgH2 text="使い方" />
      <Space height={40} />
      <div className="grid justify-items-center gap-4 sm:grid-cols-2 grid-cols-1">
        {steps.map((step, index) => (
          <div
            key={index}
            className="max-w-sm rounded-2xl border border-gray bg-white p-4 shadow-md"
          >
            <Space height={8} />
            <h3 className="text-base">{step.title}</h3>
            <Space height={8} />
            <img src={step.image} alt={step.alt} className="w-full" />
            <Space height={8} />
            <p className="text-xs">{step.description}</p>
          </div>
        ))}
      </div>
      <Space height={40} />
    </>
  );
};
