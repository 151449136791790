import { AnimeType } from "@gengakuji/common";
import { doc, getDoc } from "firebase/firestore";
import { getDefaultStore } from "jotai";

import { languageAtom } from "../atoms";
import { db } from "../firebase";

import { getProduct } from "./products";

const { get } = getDefaultStore();

export const getAnime = async (
  productId: string
): Promise<AnimeType | null> => {
  // まずproductデータを取得
  const product = await getProduct(productId);
  if (!product) {
    return null;
  }

  // animeIdを取得
  const animeId = product.animeId;
  if (!animeId) {
    return null;
  }

  // animeデータを取得
  const animeRef = doc(db, "anime", animeId);
  const animeSnap = await getDoc(animeRef);
  if (!animeSnap.exists()) {
    return null;
  }

  const anime = animeSnap.data() as AnimeType;
  const language = get(languageAtom);

  let selectedTranslation;
  // 現在の言語がproductのavailableLanguagesに含まれている場合はその翻訳を使う
  if (product.availableLanguages?.includes(language)) {
    selectedTranslation = anime.translations[language];
  } else {
    // そうでなければtranslationsの最初のキーの翻訳を採用
    const translationKeys = Object.keys(anime.translations || {});
    if (translationKeys.length > 0) {
      selectedTranslation = anime.translations[translationKeys[0]];
    }
  }

  if (!selectedTranslation) {
    return null;
  }

  // もし翻訳が取得できた場合は、animeプロパティとして上書きして返す
  return {
    ...anime,
    ...selectedTranslation,
  };
};
