import { memo } from "react";
import { Virtuoso } from "react-virtuoso";

interface Props {
  images: string[];
}

export const Parapara = memo(({ images }: Props) => {
  // 上下レンダリング範囲の制御（ピクセル）
  const increaseViewportBy = 5000;

  return (
    <>
      <style>
        {`
          .clip-inset {
            clip-path: inset(0);
          }
        `}
      </style>
      <Virtuoso
        useWindowScroll
        data={images}
        increaseViewportBy={increaseViewportBy}
        itemContent={(_, image) => (
          <>
            <div className="h-[70.6vh] md:h-[508px] clip-inset">
              <div
                className="fixed left-1/2 top-1/2 size-full -translate-x-1/2 -translate-y-1/2 bg-transparent bg-[length:calc(100vw-32px)] md:bg-[length:720px] bg-center bg-no-repeat"
                style={{ backgroundImage: `url(${image})` }}
              ></div>
            </div>
            <div className="h-2 w-full"></div>
          </>
        )}
      />
    </>
  );
});
