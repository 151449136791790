import { useAtom } from "jotai";
import { useEffect } from "react";

import { languageAtom } from "../atoms";
import i18n from "../i18n";

export const useI18nSync = () => {
  const [language] = useAtom(languageAtom);

  useEffect(() => {
    // languageAtom が変わるたびに i18n.changeLanguage を呼ぶ
    i18n.changeLanguage(language);
  }, [language]);
};
