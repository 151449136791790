import { ProductType } from "@gengakuji/common";
import { useAtom } from "jotai";
import { useState } from "react";

import {
  countryCodeAtom,
  isBuyBoxModalOpenAtom,
  languageAtom,
} from "../../atoms";
import { buyKuji } from "../../utils";
import { BaseButton } from "../Button/BaseButton";
import { DisabledButton } from "../Button/DisabledButton";
import { Space } from "../Space";

interface BuyBoxModalProps {
  product: ProductType | null;
  leftCount: number;
}

export const BuyBoxModal = ({ product, leftCount }: BuyBoxModalProps) => {
  const [language] = useAtom(languageAtom);

  const [drawNumber, setDrawNumber] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isBuyBoxModalOpenState, setIsBuyBoxModalOpenState] = useAtom(
    isBuyBoxModalOpenAtom
  );
  const [countryCode] = useAtom(countryCodeAtom);

  const amount = countryCode ? product?.countryPrices[countryCode]?.amount : 0;

  const handleBuyKuji = async () => {
    setIsLoading(true);
    try {
      if (drawNumber) {
        await buyKuji(drawNumber, product, language);
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 z-[1000] bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
      <div className="bg-white rounded-lg p-4 w-full max-w-[min(calc(100%-32px),448px)]">
        <div className="mb-4">
          <select
            id="drawNumber"
            value={drawNumber ?? ""}
            onChange={(e) =>
              setDrawNumber(
                e.target.value === "" ? null : Number(e.target.value)
              )
            }
            className="w-full p-2 border rounded m-0 mr-2 h-10 border-[#cacaca] bg-white text-[16px] text-black focus:shadow-[0_0_2px_#cacaca] focus:outline-none"
          >
            <option value="" disabled hidden selected>
              回数を選択
            </option>
            {Array.from({ length: leftCount }, (_, i) => i + 1).map((num) => (
              <option key={num} value={num}>
                {num} 回
              </option>
            ))}
          </select>
        </div>

        {/* drawNumber が選択されていない場合はDisbaleButtonを表示 */}
        <div className="mb-4">
          {drawNumber !== null ? (
            <>
              <p className="mb-4 text-center">
                ¥
                <span className="text-xl">
                  {new Intl.NumberFormat("ja-JP", {}).format(
                    amount! * drawNumber
                  )}
                </span>
                （税込）
              </p>
              <BaseButton
                onClick={handleBuyKuji}
                title="支払いに進む"
                isLoading={isLoading}
              />
              <Space height={8} />
              <p className="text-xs text-[#999999]">決済画面に遷移します</p>
            </>
          ) : (
            <>
              <p className="mb-4 text-center">
                ¥
                <span className="text-xl">
                  {new Intl.NumberFormat("ja-JP").format(0)}
                </span>
              </p>
              <DisabledButton title="支払いに進む" />
            </>
          )}
        </div>
        <p
          className="text-gray cursor-pointer border-none bg-transparent text-xs hover:underline"
          onClick={() => setIsBuyBoxModalOpenState(false)}
        >
          キャンセル
        </p>
      </div>
    </div>
  );
};
