import { sendPasswordResetEmail } from "firebase/auth";
import { memo, useState } from "react";

import { REDIRECT_URL } from "../../constants/RedirectURL";
import { auth } from "../../firebase";
import { handleEmailChange } from "../../utils";
import { BaseButton } from "../Button/BaseButton";
import { DisabledButton } from "../Button/DisabledButton";
import { ColorSpan } from "../ColorSpan";
import { H2 } from "../H2";
import { Input } from "../Input";
import { Space } from "../Space";

import { BaseModal } from "./BaseModal";

interface Props {
  onClose: () => void;
}

export const ResetModal = memo(({ onClose }: Props) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [sendEmailInfo, setSendEmailInfo] = useState<string | null>(null);

  const sendEmailForResettingPassword = () => {
    setIsLoading(true);
    setSendEmailInfo("");
    auth.languageCode = "ja";
    sendPasswordResetEmail(auth, email, {
      url: REDIRECT_URL,
    })
      .then(() => {
        setSendEmailInfo("リセット用メールが送信されました。");
      })
      .catch((error) => {
        console.error(error);
        setSendEmailInfo("メールの送信に失敗しました。");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isFormValid = !emailError && email;
  return (
    <>
      <BaseModal bgColor="bg-white" showCross={false}>
        <H2 text="パスワード変更" blue={4} />
        <Input
          type="email"
          placeholder="メールアドレス"
          value={email}
          onChange={(e) => handleEmailChange(e, setEmail, setEmailError)}
          errorMessage={emailError}
        />
        {sendEmailInfo ? (
          <>
            <Space height={12} />
            <p>{sendEmailInfo}</p>
            <Space height={12} />
          </>
        ) : (
          <Space height={48} />
        )}
        {isFormValid ? (
          <BaseButton
            title="リセット用メールを送信"
            backgroundColor="#ff4127"
            textColor="#fff"
            onClick={sendEmailForResettingPassword}
            isLoading={isLoading}
          />
        ) : (
          <DisabledButton title="リセット用メールを送信" />
        )}
        <Space height={40} />
        <div onClick={onClose}>
          <ColorSpan>閉じる</ColorSpan>
        </div>
        <Space height={40} />
      </BaseModal>
    </>
  );
});
