// カウントダウン
export const calculateTimeLeft = (start: Date, end: Date) => {
  const now = new Date();
  let timeLeft = 0;

  if (now <= start) {
    // 販売開始前
    timeLeft = start.getTime() - now.getTime();
  } else if (
    now < end &&
    new Date(end.getTime() - 7 * 24 * 60 * 60 * 1000) < now
  ) {
    // 販売終了1週間前〜販売終了まで
    timeLeft = end.getTime() - now.getTime();
  } else {
    return null;
  }

  const daysCount = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hoursCount = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutesCount = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const secondsCount = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return { daysCount, hoursCount, minutesCount, secondsCount };
};
