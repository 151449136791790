import {
  RequestedShipInfo,
  ShippedInfo,
  TabType,
  AvailableShipInfo,
  ContactType,
} from "@gengakuji/common";
import { Timestamp } from "firebase/firestore";
import { useAtom } from "jotai";
import { memo, useEffect, useState, useCallback } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";

import { addressAtom, languageAtom } from "../atoms";
import { BgH2 } from "../components/BgH2";
import { RequestModal } from "../components/Modal/RequestModal";
import { Space } from "../components/Space";
import { Tab } from "../components/Tab";
import { TabRenderContent } from "../components/TabRenderContent";
import { getShipInfo, getShippedInfo, getContacts } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
import { convertToFirestoreTimestamp } from "../utils/convertToFirestoreTimestamp";

const title = "配送管理";

const Shipping = memo(() => {
  const [language] = useAtom(languageAtom);
  const [activeTab, setActiveTab] = useState<TabType>("request");
  const [showModal, setshowModal] = useState(false);
  const [availableShipInfo, setAvailableShipInfo] = useState<
    AvailableShipInfo[] | null
  >(null);
  const [requestShip, setRequestShip] = useState<AvailableShipInfo | null>(
    null
  );
  const [requestedShipInfo, setRequestedShipInfo] = useState<
    RequestedShipInfo[] | null
  >(null);
  const [shippedInfo, setShippedInfo] = useState<ShippedInfo[] | null>(null);
  const [isLoadingRequest, setIsLoadingRequest] = useState(true);
  const [isLoadingRequested, setIsLoadingRequested] = useState(true);
  const [isLoadingShipped, setIsLoadingShipped] = useState(true);
  const [contacts, setContacts] = useState<ContactType[]>([]);
  const user = useAuthUser();
  const navigate = useNavigate();

  //既存配送先取得
  useEffect(() => {
    if (user) {
      getContacts(user.uid).then((contacts) => {
        setContacts(contacts);
      })
    }
  }, [user]);

  // タブの変更時（ハッシュ更新）にタブを同期
  const handleHashChange = useCallback(() => {
    const hash = window.location.hash.replace("#", "") || "request";
    if (hash === "request" || hash === "requested" || hash === "shipped") {
      setActiveTab(hash);
    } else {
      setActiveTab("request");
    }
  }, []);

  // マウント時＆ハッシュ変更時にタブを同期
  useEffect(() => {
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, [handleHashChange]);

  // タブの変更時（ユーザー操作）にハッシュ更新
  const onChangeTab = useCallback((tab: TabType) => {
    setActiveTab(tab);
    window.location.hash = tab;
  }, []);

  // 配送依頼可能データ取得
  useEffect(() => {
    const fetchNumOfAvailableDelivery = async () => {
      if (user == null) {
        navigate(`/login?from=shipping`);
        return;
      } else {
        const idToken = await user.getIdToken();
        try {
          const res = await fetch(
            process.env.REACT_APP_GET_AVAILABLE_DELIVERIESINFO!,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              },
              // リクエストボディに language を含める
              body: JSON.stringify({ language }),
            }
          );
          if (res.status === 204) return;
          const data = await res.json();
          if (data) {
            convertToFirestoreTimestamp(data);
            setAvailableShipInfo(
              data.availableDeliveriesInfo as AvailableShipInfo[]
            );
          }
        } catch (e) {
          console.error(e);
        } finally {
          setIsLoadingRequest(false);
        }
      }
    };
    if (activeTab === "request") {
      fetchNumOfAvailableDelivery();
    }
  }, [activeTab, user, navigate, language]);

  // 配送依頼済みデータ取得
  useEffect(() => {
    const fetchRequestedInfo = async () => {
      if (user) {
        const data = await getShipInfo(user.uid);
        if (data.length > 0) {
          setRequestedShipInfo(data);
        }
      }
      setIsLoadingRequested(false);
    };
    if (activeTab === "requested") {
      fetchRequestedInfo();
    }
  }, [activeTab, user, language]);

  // 発送済みデータ取得
  useEffect(() => {
    const fetchShippedInfo = async () => {
      if (user) {
        const data = await getShippedInfo(user.uid);
        if (data.length > 0) {
          setShippedInfo(data);
        }
      }
      setIsLoadingShipped(false);
    };
    if (activeTab === "shipped") {
      fetchShippedInfo();
    }
  }, [activeTab, user, language]);

  // 商品を選択
  const chooseOneProduct = (item: AvailableShipInfo) => {
    if (!availableShipInfo) return;
    setRequestShip(item);
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <Space height={24} />
      <BgH2 text={title} />
      <Space height={24} />
      <Tab activeTab={activeTab} onChangeTab={onChangeTab} />
      <div className="flex min-h-[calc(100vh-116px)] justify-center rounded-b-2xl bg-white p-0 px-4 shadow-md">
        <TabRenderContent
          activeTab={activeTab}
          isLoadingRequest={isLoadingRequest}
          isLoadingRequested={isLoadingRequested}
          isLoadingShipped={isLoadingShipped}
          availableShipInfo={availableShipInfo}
          requestedShipInfo={requestedShipInfo}
          shippedInfo={shippedInfo}
          setshowModal={setshowModal}
          chooseOneProduct={chooseOneProduct}
        />
      </div>
      <Space height={16} />
      <div>
        {showModal && (
          <RequestModal
            productData={requestShip}
            isLoadingRequest={isLoadingRequest}
            onClose={() => setshowModal(false)}
            contacts={contacts}
          />
        )}
      </div>
    </>
  );
});

export default Shipping;
