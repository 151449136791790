import { BoxType } from "@gengakuji/common";
import { useAtom } from "jotai";

import { isResetBoxModalOpenAtom } from "../../atoms";
import { BaseButton } from "../Button/BaseButton";

interface BoxResetModalProps {
  onConfirm: () => void;
  isLoading: boolean;
  box: BoxType | null;
}

export const ResetBoxModal = ({
  onConfirm,
  isLoading,
  box,
}: BoxResetModalProps) => {
  const [isResetBoxModalOpenState, setIsResetBoxModalOpenState] = useAtom(
    isResetBoxModalOpenAtom
  );

  return (
    <div className="fixed inset-0 z-[1000] bg-[rgba(0,0,0,0.5)] flex items-center justify-center">
      <div className="bg-white rounded-lg p-4 w-full max-w-[min(calc(100%-32px),448px)]">
        <div className="mb-4">
          <p>
            ボックスの中身をリセットしますか？
            <br />
            {box?.leftCount !== 0 &&
              "一度リセットすると、元のボックスからくじを引くことはできません。"}
          </p>
        </div>
        <div className="mb-4">
          <BaseButton
            onClick={onConfirm}
            title="リセットする"
            isLoading={isLoading}
          />
        </div>
        <p
          className="text-gray cursor-pointer border-none bg-transparent text-xs hover:underline"
          onClick={() => setIsResetBoxModalOpenState(false)}
        >
          キャンセル
        </p>
      </div>
    </div>
  );
};
