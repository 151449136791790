// hooks/useFirebaseAuth.ts
import { useAtom } from "jotai";
import { useEffect } from "react";

import { authAtom, Auth } from "../atoms";
import { auth } from "../firebase"; // firebase の設定をインポート

export const useFirebaseAuthInit = () => {
  const [, setAuth] = useAtom(authAtom);

  useEffect(() => {
    // Firebase でログインユーザーが変わるたびに呼ばれる
    const unsubscribe = auth.onIdTokenChanged((user) => {
      if (!user) {
        // 未ログイン
        setAuth({
          user: null,
          initialized: true,
        });
      } else {
        // ログイン中
        const loginMethods = user.providerData.map((info) => info.providerId);
        setAuth({
          user: {
            uid: user.uid,
            email: user.email,
            loginMethods,
            getIdToken: () => user.getIdToken(),
          },
          initialized: true,
        } as Auth);
      }
    });

    return () => {
      // コンポーネントがアンマウントされる際にリスナーを解除
      unsubscribe();
    };
  }, [setAuth]);
};
