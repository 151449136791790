export const KUJI_TYPE = {
  Normal: "Normal",
  S: "S",
} as const;

export type KujiType = "Normal" | "S";

export type CutInfoCommonType = {
  index: number;
  cutId: string;
  productId: string;
  type: KujiType;
  mixedCount: number;
  rawCount: number;
  translations: {
    [language: string]: CutInfoTranslationsType;
  };
};

export type CutInfoTranslationsType = {
  displayName: string;
  animator: string[];
  character: string[];
};

export type CutInfoType = CutInfoCommonType & CutInfoTranslationsType;
