import { atom } from "jotai";

/**
 * アプリ全体の言語情報を保持するアトム。
 * デフォルトは "ja" に設定。
 */
export const languageAtom = atom<string>("ja");

export const languageListAtom = atom<string[]>(["ja", "en"]);

export const countryCodeAtom = atom<string | null>(null);
