import { useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { languageAtom, languageListAtom } from "../atoms";

import { Select } from "./Select";

export const Header = () => {
  const { t } = useTranslation();

  const [language, setLanguage] = useAtom(languageAtom);
  const [languageList] = useAtom(languageListAtom);

  // 下方向スクロール中か上方向スクロール中かを監視する
  const [hidden, setHidden] = useState(false);
  const lastScrollYRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      let currentScrollY = window.scrollY;
      // iOS Safari のバウンスで負の値にならないように 0 でクランプ
      if (currentScrollY < 0) {
        currentScrollY = 0;
      }

      if (currentScrollY <= 0) {
        // ページ最上部では必ずヘッダーを表示
        setHidden(false);
      } else if (currentScrollY > lastScrollYRef.current) {
        // 下方向スクロール
        setHidden(true);
      } else {
        // 上方向スクロール
        setHidden(false);
      }

      lastScrollYRef.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={`
        fixed inset-x-0 top-0 z-[1000]
        bg-[rgba(255,255,255,0.1)] 
        shadow-md 
        backdrop-blur-[2px]
        transition-transform duration-300
        ${hidden ? "-translate-y-full" : "translate-y-0"}
      `}
    >
      <div className="mx-auto flex h-14 max-w-3xl items-center justify-between p-4">
        <div className="flex h-full w-2/5 shrink-0 items-center">
          <Link to="/" className="inline-block h-auto w-full">
            <img
              src={require("../assets/logo/header-logo.webp")}
              alt={t("header.logoAlt")}
              className="h-auto max-w-full align-middle"
            />
          </Link>
        </div>
        {/* <Select
          id="language"
          value={language}
          onChange={(e) => setLanguage(e.target.value)}
        >
          {languageList.map((lang) => (
            <option key={lang} value={lang}>
              {lang.toUpperCase()}
            </option>
          ))}
        </Select> */}
      </div>
    </header>
  );
};
