import { ContactType, FirestoreDocument } from "@gengakuji/common";
import { collection, getDocs } from "firebase/firestore";

import { db } from "../firebase";

export const getContacts = async (uid: string) => {
  try {
    const contacts: FirestoreDocument<ContactType>[] = [];
    const contactsRef = collection(db, "users", uid, "contacts");
    const contactsSnap = await getDocs(contactsRef);
    contactsSnap.forEach((doc) => {
      contacts.push({
        id: doc.id,
        ...doc.data(),
      } as FirestoreDocument<ContactType>);
    });
    return contacts;
  } catch (error) {
    console.error("Error fetching contacts:", error);
    throw error;
  }
};
