import { Timestamp } from "firebase/firestore";

export const convertToFirestoreTimestamp = (obj: any) => {
  for (const key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      if (
        obj[key].seconds !== undefined &&
        obj[key].nanoseconds !== undefined
      ) {
        obj[key] = new Timestamp(obj[key].seconds, obj[key].nanoseconds);
      } else {
        convertToFirestoreTimestamp(obj[key]);
      }
    }
  }
};
