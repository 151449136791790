import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const userLinks = [
  {
    to: "/",
    src: require("../assets/icon/home.webp"),
    alt: "header.home",
    text: "header.home",
  },
  {
    to: "/shipping",
    src: require("../assets/icon/shipping.webp"),
    alt: "header.shippingAlt",
    text: "header.shipping",
  },
  {
    to: "/collection",
    src: require("../assets/icon/collection.webp"),
    alt: "header.collectionAlt",
    text: "header.collection",
  },
  {
    to: "/mypage",
    src: require("../assets/icon/mypage.webp"),
    alt: "header.mypageAlt",
    text: "header.mypage",
  },
];

export const BottomMenu = () => {
  const { t } = useTranslation();
  // 現在のopacity用クラス名を状態管理
  const [opacityClass, setOpacityClass] = useState("opacity-100");
  // 前回のスクロール位置を記憶するためのref
  const lastScrollYRef = useRef(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // ★Safari対策: 一番上で止まった際は常にopacity-100にする
      if (currentScrollY <= 0) {
        setOpacityClass("opacity-100");
        lastScrollYRef.current = 0;
        return;
      }

      const diff = currentScrollY - lastScrollYRef.current;

      if (diff > 0) {
        // 下スクロール時
        setOpacityClass("opacity-30");
      } else {
        // 上スクロールまたはスクロール停止時
        setOpacityClass("opacity-100");
      }

      lastScrollYRef.current = currentScrollY;
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <header className="fixed inset-x-0 bottom-0 z-[1000] bg-[rgba(255,255,255,0.1)] shadow-md backdrop-blur-[2px]">
      <div className="mx-auto flex h-14 max-w-3xl items-center justify-between p-4">
        <div className="flex w-full">
          {userLinks.map((link, index) => (
            <div key={index} className="flex flex-col items-center flex-1">
              <Link
                to={link.to}
                className={`flex flex-col items-center text-black no-underline transition-opacity duration-300 ${opacityClass}`}
              >
                <img
                  src={link.src}
                  alt={t(link.alt)}
                  className="mb-1 max-h-[28px] w-auto"
                />
                <p className="text-[8px]">{t(link.text)}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </header>
  );
};
