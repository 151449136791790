import {
  AnimeType,
  ProductType,
  CutInfoType,
  FirestoreDocument,
  BoxType,
} from "@gengakuji/common";
import { logEvent } from "firebase/analytics";
import { useAtom } from "jotai";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";

import {
  isBuyBoxModalOpenAtom,
  isResetBoxLoadingAtom,
  isResetBoxModalOpenAtom,
  languageAtom,
} from "../atoms";
import { BgH2 } from "../components/BgH2";
import { BaseButton } from "../components/Button/BaseButton";
import { FixedBottom } from "../components/FixedBottom";
import { Img } from "../components/Img";
import { Loading } from "../components/Loading";
import { BuyBoxModal } from "../components/Modal/BuyBoxModal";
import { ResetBoxModal } from "../components/Modal/ResetBoxModal";
import { PasswordForm } from "../components/PasswordForm";
import { Share } from "../components/Share";
import { Space } from "../components/Space";
import { analytics } from "../firebase";
import { getCutInfos, getProduct, getUser, getAnime } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
import { fetchBoxContent } from "../utils/fetchBoxContent";
import { buyKuji, convertTimeStampToDate } from "../utils/index";
import { resetUserBox } from "../utils/resetBox";

const title = "サンプル一覧";

const SampleCollection = memo(() => {
  const [language] = useAtom(languageAtom);
  const { productId } = useParams<{ productId?: string }>();
  const user = useAuthUser();
  const [isLoading, setIsLoading] = useState(true);
  const [cutInfo, setCutInfo] = useState<FirestoreDocument<CutInfoType>[]>();
  const [productData, setProductData] = useState<ProductType | null>(null);
  const [animeData, setAnimeData] = useState<AnimeType | null>(null);
  const [boxData, setBoxData] = useState<BoxType | null>(null);
  const [firstBuy, setFirstBuy] = useState(false);

  const [isLogin, setIsLogin] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCorrectPassword, setIsCorrectPassword] = useState(false);

  //ログイン判定
  useEffect(() => {
    setIsLogin(!!user?.uid);
  }, [user]);

  // Box購入モーダル関連
  const [isBuyBoxModalOpenState, setIsBuyBoxModalOpenState] = useAtom(
    isBuyBoxModalOpenAtom
  );

  // Boxリセットモーダル関連
  const [isResetBoxModalOpenState, setIsResetBoxModalOpenState] = useAtom(
    isResetBoxModalOpenAtom
  );
  const [isResetBoxLoadingState, setIsResetBoxLoadingState] = useAtom(
    isResetBoxLoadingAtom
  );

  const handleResetBox = async () => {
    setIsResetBoxLoadingState(true);
    try {
      if (!productData?.productId) return;
      const newBox = await resetUserBox(productData.productId);
      setBoxData(newBox);
    } catch (error) {
      console.error("Boxリセットに失敗", error);
    } finally {
      setIsResetBoxLoadingState(false);
      setIsResetBoxModalOpenState(false);
      // 必要に応じて、リセット直後に購入モーダルを開くなら下記を呼ぶ
      setIsBuyBoxModalOpenState(true);
    }
  };

  //商品・カット情報取得・初回購入判定
  useEffect(() => {
    const fetchData = async () => {
      if (!productId) return;

      const productPromise = getProduct(productId);
      const animePromise = getAnime(productId);
      const cutInfoPromise = getCutInfos(productId);

      const [productData, animeData, cutInfo] = (await Promise.all([
        productPromise,
        animePromise,
        cutInfoPromise,
      ])) as [ProductType, AnimeType, FirestoreDocument<CutInfoType>[]];

      setProductData(productData);
      setAnimeData(animeData);
      setCutInfo(cutInfo);

      const now = new Date();
      const open = new Date(productData.open.toDate());
      setIsOpen(now > open); // 情報解禁判定

      if (productData?.box.isBox) {
        const boxData = await fetchBoxContent(productId);
        setBoxData(boxData);
      }

      if (user?.uid) {
        const userData = await getUser(user.uid);
        setFirstBuy(!userData?.boughtProductIds.includes(productId));
      }

      setIsLoading(false);
    };

    fetchData();
  }, [productId, user, language]);

  //くじを購入する
  const handleBuyKuji = async (drawNumber: number) => {
    await buyKuji(drawNumber, productData, language);
  };

  if (isLoading) {
    return (
      <>
        <Space height={64} />
        <Loading />
        <Space height={64} />
      </>
    );
  }

  if (!isOpen && !isCorrectPassword) {
    return (
      <PasswordForm
        productId={productId}
        correctPassword={productData?.password}
        onPasswordCorrect={() => setIsCorrectPassword(true)}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {title}/{productId}
        </title>
      </Helmet>
      <Space height={24} />
      <BgH2 text={title} />
      <Space height={24} />
      <p>
        サンプルのスクリーン録画を
        <br />
        SNSにシェアすることは大歓迎！
      </p>
      <Space height={24} />
      <div className="mx-auto grid grid-cols-2 gap-4">
        {cutInfo?.map((val, index) => (
          <div key={index}>
            <Link
              to={`${val.id}`}
              onClick={() =>
                logEvent(
                  analytics,
                  `${val.displayName}${
                    window.location.pathname + window.location.search
                  }`
                )
              }
            >
              <Img
                imgPath={`${productData?.productId}/cover/${val.index}.png`}
                alt={`商品画像${index + 1}`}
                className="w-full shadow-md"
              />
            </Link>
            <Space height={16} />
            <p>{val.displayName}</p>
          </div>
        ))}
      </div>
      <Space height={48} />
      <Share
        url={`https://gengakuji.com/${productData?.productId}/sample`}
        text="【登録不要】縦スクロールでパラパラ動くデジタル原画の無料サンプル公開中！"
        hashtags={
          animeData?.hashtags
            ? [...animeData.hashtags, "原画くじ"]
            : ["原画くじ"]
        }
      />
      <Space height={48} />
      <Link to={`/${productData?.productId}`}>
        <BaseButton
          title="商品ページへ"
          backgroundColor="green"
          textColor="#000"
        />
      </Link>
      <Space height={128} />
      <FixedBottom
        user={user}
        productData={productData}
        start={
          productData ? convertTimeStampToDate(productData.start) : new Date()
        }
        end={productData ? convertTimeStampToDate(productData.end) : new Date()}
        firstBuy={firstBuy}
        handleBuyKuji={handleBuyKuji}
        box={boxData}
        isLogin={isLogin}
        animeData={animeData}
      />
      {/* モーダル表示部分 */}
      <>
        {isResetBoxModalOpenState && (
          <ResetBoxModal
            onConfirm={handleResetBox}
            isLoading={isResetBoxLoadingState}
            box={boxData}
          />
        )}
        {isBuyBoxModalOpenState && (
          <BuyBoxModal
            product={productData}
            leftCount={boxData?.leftCount ?? 1}
          />
        )}
      </>
    </>
  );
});

export default SampleCollection;
