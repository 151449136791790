import { memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
  fullHeight?: boolean;
};

export const BaseWhiteBg = memo(({ children, fullHeight = true }: Props) => (
  <div className="flex justify-center">
    <div
      className={`my-4 ${
        fullHeight ? "min-h-screen" : ""
      } max-w-3xl flex-1 rounded-2xl bg-white px-4 shadow-md`}
    >
      {children}
    </div>
  </div>
));
