import {
  CollectionType,
  FirestoreDocument,
  CollectionListType,
} from "@gengakuji/common";
import { ProductType } from "@gengakuji/common";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { getDefaultStore } from "jotai";

import { languageAtom } from "../atoms";
import { db } from "../firebase";

const { get } = getDefaultStore();

export const getCollection: (
  uid: string,
  productId: string
) => Promise<FirestoreDocument<CollectionType>[]> = async (
  uid: string,
  productId: string
) => {
  const collections: FirestoreDocument<CollectionType>[] = [];
  // productDataを取得する処理を追加する
  const collectionRef = collection(db, "collections", uid, productId);
  const collectionSnap = await getDocs(collectionRef);
  collectionSnap.forEach((doc) => {
    collections.push({
      id: doc.id,
      ...doc.data(),
    } as FirestoreDocument<CollectionType>);
  });

  return collections;
};

export const getCollectionList = async (
  uid: string
): Promise<CollectionListType> => {
  const language = get(languageAtom);

  // ユーザーデータの取得
  const userDoc = await getDoc(doc(db, "users", uid));
  const userData = userDoc.data();
  if (!userData) {
    console.error("No User: ", uid);
    return null;
  }

  const boughtProductIds = userData.boughtProductIds as string[];
  if (boughtProductIds.length === 0) {
    // 購入済みのプロダクトなし
    return null;
  }

  let collectionList: CollectionListType = [];
  await Promise.all(
    boughtProductIds.map(async (productId) => {
      // プロダクト情報取得
      const productDoc = await getDoc(doc(db, "products", productId));
      const productInfo = productDoc.data() as ProductType;

      // 所持数の取得
      const collectionQuery = collection(db, "collections", uid, productId);
      const collectionSnapshot = await getDocs(collectionQuery);
      const collectionCount = collectionSnapshot.size;

      collectionList!.push({
        collectionCount,
        product: {
          count: productInfo.count,
          productId,
          productName: productInfo.translations[language].productName,
          start: productInfo.start,
        },
      });
    })
  );

  collectionList.sort((a, b) => {
    const startCompare =
      b.product.start.toDate().getTime() - a.product.start.toDate().getTime();
    if (startCompare !== 0) {
      return startCompare;
    }
    return a.product.productName.localeCompare(b.product.productName);
  });

  return collectionList;
};

export const sortCollectionByIndex = (
  collections: FirestoreDocument<CollectionType>[]
): FirestoreDocument<CollectionType>[] => {
  return collections.sort((a, b) => {
    return a.cutInfo.index - b.cutInfo.index;
  });
};
