import { useTranslation } from "react-i18next";

import { Space } from "./Space";

export const Footer = () => {
  const { t } = useTranslation();

  const links = [
    { href: "https://avaren.co.jp/gengakuji-terms", text: t("footer.terms") },
    {
      href: "https://avaren.co.jp/gengakuji-privacy",
      text: t("footer.privacy"),
    },
    {
      href: "https://avaren.co.jp/gengakuji-company",
      text: t("footer.company"),
    },
    { href: "https://avaren.co.jp/gengakuji-scta", text: t("footer.scta") },
    { href: "https://avaren.co.jp/gengakuji-help", text: t("footer.faq") },
    { href: "/contact", text: t("footer.contact") },
    { href: "https://twitter.com/gengakuji", text: t("footer.twitter") },
  ];

  return (
    <div className="bg-green">
      <Space height={16} />
      <div className="mx-auto max-w-3xl p-8 text-center">
        <img
          src={require("../assets/logo/logo.webp")}
          alt="ロゴ"
          width={"100%"}
        />
        <Space height={32} />
        <div className="text-left">
          {links.map((link, index) => (
            <div key={index}>
              <a
                href={link.href}
                className="text-sm text-black no-underline"
                // 「問い合わせ」だけ別タブにしないという条件は翻訳キーでもOK
                target={link.text === t("footer.contact") ? "_self" : "_blank"}
                rel={
                  link.text === t("footer.contact")
                    ? undefined
                    : "noopener noreferrer"
                }
              >
                {link.text}
              </a>
              <Space height={12} />
            </div>
          ))}
        </div>
        <Space height={32} />
        <img
          src={require("../assets/logo/footer-logo.webp")}
          alt="ロゴ"
          width={"100%"}
        />
        <Space height={32} />
        <p>(C) GENGAKUJI</p>
        <Space height={128} />
      </div>
    </div>
  );
};
