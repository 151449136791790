import { logEvent } from "firebase/analytics";
import { memo } from "react";

import { analytics } from "../../firebase";
import { Loading } from "../Loading";

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  icon?: string;
  title: string;
  backgroundColor?: string;
  textColor?: string;
  width?: string;
  border?: string;
  isLoading?: boolean;
  disabled?: boolean;
  gaEvent?: string;
}

export const YubiButton = memo(
  ({
    onClick,
    title,
    backgroundColor = "#ff4127",
    textColor = "#fff",
    width = "100%",
    border,
    isLoading = false,
    disabled = false,
    icon = require("../../assets/icon/yubi.webp"),
    gaEvent = `${title}${window.location.pathname + window.location.search}`,
  }: Props) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (disabled || isLoading) return;
      if (onClick) {
        onClick(e);
      }
      if (gaEvent) {
        logEvent(analytics, gaEvent);
      }
    };

    return (
      <button
        className={`relative h-[52px] max-w-[320px] rounded-full border border-b-[6px] border-black text-center text-sm font-bold ${
          disabled ? "cursor-not-allowed opacity-60" : "cursor-pointer"
        }`}
        onClick={handleClick}
        style={{ backgroundColor, color: textColor, width, border }}
        disabled={disabled || isLoading}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <span className="relative z-10 font-bold">{title}</span>
            <img
              src={icon}
              alt="icon"
              className="absolute right-4 top-1/2 size-[58px] -translate-y-1/2 z-0"
            />
          </>
        )}
      </button>
    );
  }
);
