import { BaseWhiteBg } from "./BaseWhiteBg";
import { BgH2 } from "./BgH2";
import { Img } from "./Img";
import { Space } from "./Space";

type Props = {
  productId: string;
};

export const AllDraw = ({ productId }: Props) => {
  return (
    <BaseWhiteBg fullHeight={false}>
      <Space height={16} />
      <BgH2 text="オールドロー賞" />
      <Space height={16} />
      <p className="bg-red inline-block rounded p-2 text-base text-white ">
        残り有り
      </p>
      <Space height={16} />
      <p className="text-left">
        大好評につき感謝の気持ちを込めてスペシャルな特典が追加！
        <br />
        Boxの中身全て引くごとに実際のアニメ制作で使用された
        <span className="text-red">「世界に一つだけの生原画」</span>
        がランダムで1枚手に入る！
      </p>
      <Space height={16} />
      <p className="text-left text-sm text-gray">
        ※本物の生原画のため、無くなり次第特典は終了となります。
        <br />
        ※特典が終了した場合は、サイトにてお知らせいたします。
        <br />
        ※すでにBoxの中身をすべて引いた方も対象です。
        <br />
        ※画像は特典の一例です。
        <br />
        ※どの生原画が当選したかは、発送をもってお知らせいたします。
        <br />
        ※お届けのために、くじの商品に対して配送依頼を行う必要があります。
        <br />
        ※一度配送依頼を行うと、以降の配送依頼は送料無料となります。
      </p>

      <Space height={16} />
      <Img
        imgPath={`${productId}/alldraw.png`}
        alt="オールドロー賞画像"
        className="mx-auto rounded-xl"
      />
      <Space height={16} />
    </BaseWhiteBg>
  );
};
