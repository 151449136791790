import { ProductType, SaleStatus } from "@gengakuji/common";
import { useAtom } from "jotai";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";

import { languageAtom } from "../atoms";
import { BaseButton } from "../components/Button/BaseButton";
import { ProductCard } from "../components/Card/ProductCard";
import { How } from "../components/How";
import { Loading } from "../components/Loading";
import { Share } from "../components/Share";
import { Space } from "../components/Space";
import { getProductList } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";

const title = "原画くじ一覧";

const ProductList = memo(() => {
  const [language] = useAtom(languageAtom);
  const [productList, setProductList] = useState<
    (ProductType & { saleStatus: SaleStatus })[] | null
  >(null);
  const [loading, setLoading] = useState(true);
  const [isLogin, setIsLogin] = useState(false);
  const user = useAuthUser();

  //ログイン判定
  useEffect(() => {
    setIsLogin(!!user?.uid);
  }, [user]);

  //商品一覧を取得
  useEffect(() => {
    const fetch = async () => {
      const data = await getProductList();
      setProductList(data);
      setLoading(false);
    };
    fetch();
  }, [language]);

  if (loading) {
    return (
      <>
        <Space height={56} />
        <Loading />
        <Space height={56} />
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <>
        {productList
          ? productList.map((value) => {
              return (
                <ProductCard
                  productId={value.productId}
                  productName={value.productName}
                  status={value.saleStatus}
                  start={value.start}
                  end={value.end}
                  key={value.productId}
                  isBox={value.box.isBox}
                />
              );
            })
          : null}
        <How />
        <Share
          url="https://gengakuji.com/"
          text="ハズレなしの原画くじを引いて豪華賞品をゲット！"
          hashtags={["原画くじ"]}
        />
        <Space height={48} />
        {isLogin ? null : (
          <div className="fixed bottom-4 z-[999] flex w-full max-w-[calc(100vw-32px)] justify-center min-[722px]:max-w-[690px]">
            <Link to={`/register`} className="flex-1">
              <BaseButton title="登録・ログインしてくじを引く" />
            </Link>
          </div>
        )}
      </>
    </>
  );
});

export default ProductList;
