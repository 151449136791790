import { ProductType, ResultType } from "@gengakuji/common";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

import { db } from "../firebase";

import { getCutInfos } from "./cutInfo";
import { getProduct } from "./products";

export const kujiResultListener = (
  uid: string,
  sessionId: string,
  callback: (data: ResultType) => void
) => {
  const resultRef = doc(db, "kuji_results", uid, "result", sessionId);

  const unsub = onSnapshot(resultRef, (docSnapshot) => {
    // 非同期処理を即時実行関数でラップして行います
    (async () => {
      const resultData = docSnapshot.data() as ResultType;

      // productId が存在する場合、マスターの cut_info データを取得し、displayName を上書き
      if (resultData && resultData.productId) {
        const masterCutInfos = await getCutInfos(resultData.productId);
        resultData.result = resultData.result.map((cut) => {
          const masterCut = masterCutInfos.find(
            (master) => master.index === cut.index
          );
          // マスターのデータが見つかった場合は displayName を上書き
          if (masterCut) {
            return { ...cut, displayName: masterCut.displayName };
          }
          return cut;
        });
      }

      // 更新済みの resultData をコールバックに渡す
      callback(resultData);

      // 一度データ取得したらリスナーを解除
      unsub();
    })();
  });

  return unsub;
};

export const getKujiResults = async (
  uid: string
): Promise<{ result: ResultType; product: ProductType }[]> => {
  const productCache: { [productId: string]: ProductType } = {};
  const results: { result: ResultType; product: ProductType }[] = [];

  const resultsRef = collection(db, "kuji_results", uid, "result");
  const queryRef = query(resultsRef, orderBy("boughtAt", "desc"));
  const resultSnap = await getDocs(queryRef);
  for (const doc of resultSnap.docs) {
    const resultData = doc.data() as ResultType;
    let productData: ProductType;
    if (productCache[resultData.productId]) {
      productData = productCache[resultData.productId];
    } else {
      const _productData = await getProduct(resultData.productId);
      if (_productData === null) continue;
      productData = _productData;
      productCache[resultData.productId] = _productData;
    }
    results.push({ result: resultData, product: productData });
  }
  return results;
};

export const getKujiResultByReciptNumber = async (
  uid: string,
  receiptNumber: string
): Promise<ResultType | null> => {
  const resultsCollection = collection(db, "kuji_results", uid, "result");
  const q = query(
    resultsCollection,
    where("customReceiptNumber", "==", receiptNumber)
  );
  const qs = await getDocs(q);
  if (qs.size > 0) {
    const docSnap = qs.docs[0];
    const resultData = docSnap.data() as ResultType;

    // マスターのcut_infoデータを取得（resultData.productId を利用）
    const masterCutInfos = await getCutInfos(resultData.productId);

    // 各結果の index に一致するマスターのデータがあれば、displayName を上書き
    resultData.result = resultData.result.map((cut) => {
      const masterCut = masterCutInfos.find(
        (master) => master.index === cut.index
      );
      if (masterCut) {
        return {
          ...cut,
          displayName: masterCut.displayName,
        };
      }
      return cut;
    });
    return resultData;
  } else {
    return null;
  }
};

// 型定義
interface KujiResult {
  count: string; //string型で保存されているため
}

export const getTotalDrawCount = async (
  productId: string,
  uid: string
): Promise<number> => {
  const kujiResultsRef = collection(db, "kuji_results", uid, "result");

  const q = query(kujiResultsRef, where("productId", "==", productId));

  const querySnapshot = await getDocs(q);

  let total = 0;
  querySnapshot.forEach((doc) => {
    const data = doc.data() as KujiResult;
    total += parseInt(data.count, 10); // stringからintへ変換して合計
  });

  return total;
};
