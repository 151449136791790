import { ProductType, ContactType } from "@gengakuji/common";
import { AddressElement, Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementLocale } from "@stripe/stripe-js";
import { useAtom } from "jotai";

import { addressAtom, languageAtom } from "../atoms";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);
interface Props {
  product: ProductType;
  contacts: ContactType[];
}
const StripeAddressElement = ({ product, contacts }: Props) => {
  const [language] = useAtom(languageAtom);
  // 住所を管理するステート
  const [, setUserAddress] = useAtom(addressAtom);

  return (
    <Elements
      stripe={stripePromise}
      options={{ locale: language as StripeElementLocale }}
    >
      <form>
        <AddressElement
          options={{
            mode: "shipping",
            fields: { phone: "always" },
            validation: {
              phone: { required: "always" },
            },
            allowedCountries: product.availableCountries, // 取得した国一覧を反映
            display: {
              name: "full",
            },
            contacts:
              contacts?.map((contact) => ({
                name: contact.name,
                phone: contact.phone,
                address: {
                  line1: contact.address.line1,
                  line2: contact.address.line2,
                  city: contact.address.city,
                  state: contact.address.state,
                  postal_code: contact.address.postal_code,
                  country: contact.address.country,
                },
              })) || [],
          }}
          onChange={(event) => {
            if (event.complete) {
              // 入力が完了したタイミングで住所情報をstateにセット
              setUserAddress({
                name: event.value.name,
                address: {
                  line1: event.value.address.line1,
                  line2: event.value.address.line2,
                  city: event.value.address.city,
                  state: event.value.address.state,
                  postal_code: event.value.address.postal_code,
                  country: event.value.address.country,
                },
                phone: event.value.phone!, // requiredなのでstringのみ
                isNewAddress: event.isNewAddress,
              });
            } else {
              setUserAddress(null);
            }
          }}
        />
      </form>
    </Elements>
  );
};
export default StripeAddressElement;
