import {
  AnimeType,
  ProductType,
  GengaInfoType,
  ApplyGengaInfoType,
  ApplyShipInfoType,
  ContactType,
} from "@gengakuji/common";
import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";

import { addressAtom, languageAtom } from "../atoms";
import { ApplyItem } from "../components/ApplyItem";
import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { BgH2 } from "../components/BgH2";
import { BaseButton } from "../components/Button/BaseButton";
import { DisabledButton } from "../components/Button/DisabledButton";
import { Fukidashi } from "../components/Fukidashi";
import { H2 } from "../components/H2";
import { Img } from "../components/Img";
import { Loading } from "../components/Loading";
import { GengaModal } from "../components/Modal/GengaModal";
import { Popup } from "../components/Popup";
import { Space } from "../components/Space";
import StripeAddressElement from "../components/StripeAddressElement";
import { WChanceTable } from "../components/Table/WChanceTable";
import {
  getGengaInfo,
  getProduct,
  getApplyGengaInfo,
  getApplyShipInfo,
  applyWChance,
  getTotalDrawCount,
  getAnime,
} from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
import { formatDate } from "../utils/index";

import NotFound from "./NotFound";

const WChance = () => {
  const [language] = useAtom(languageAtom);
  const [stripeAddress, setStripeAddress] = useAtom(addressAtom);
  const [isLoading, setIsLoading] = useState(true);
  const [validProductId, setValidProductId] = useState<boolean>(true);
  const [productData, setProductData] = useState<ProductType | null>(null);
  const [animeData, setAnimeData] = useState<AnimeType | null>(null);
  const [gengaInfo, setGengaInfo] = useState<GengaInfoType[] | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [ticketCounts, setTicketCounts] = useState<{ [key: number]: number }>(
    {}
  );
  const [restTicketCount, setRestTicketCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [selectedGengaInfo, setSelectedGengaInfo] =
    useState<GengaInfoType | null>(null);
  const [applyGengaInfo, setApplyGengaInfo] = useState<
    ApplyGengaInfoType[] | null
  >(null);
  const [shipInfo, setShipInfo] = useState<ApplyShipInfoType | null>(null);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { productId } = useParams();
  const user = useAuthUser();

  const now = new Date();

  const start = productData ? new Date(productData.start.toDate()) : now;
  const end = productData?.wchance?.applyDeadline
    ? new Date(productData.wchance.applyDeadline.toDate())
    : now;
  const formattedStart = formatDate(start);
  const formattedEnd = formatDate(end);
  const isEnd = now < end;

  const canSubmit = !!stripeAddress;

  const isLogin = !!user?.uid;

  // 臨時データ
  const [contacts, setContacts] = useState<ContactType[]>([]);

  //商品情報、原画情報、過去の応募情報の取得
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // 商品情報の取得
        if (!productId) {
          setValidProductId(false);
          setIsLoading(false);
          return;
        }
        const productData = await getProduct(productId);
        const animeData = await getAnime(productId);
        if (!productData || !animeData) {
          setValidProductId(false);
          setIsLoading(false);
          return;
        }
        setProductData(productData);
        setAnimeData(animeData);
        // 情報解禁判定
        const open = productData.open.toDate();
        setIsOpen(now > open);

        // 原画情報の取得
        const gengaInfo = await getGengaInfo(productId);
        setGengaInfo(gengaInfo);

        // ユーザーの過去の応募情報を取得
        if (user) {
          const applyGengaInfo = await getApplyGengaInfo(productId, user.uid);
          setApplyGengaInfo(applyGengaInfo);

          //くじ引いた合計回数
          const totalDrawCount = await getTotalDrawCount(productId, user.uid);
          const totalApplyCount = calculateTotalApplyCount(applyGengaInfo);
          //残りの応募可能チケット枚数
          setRestTicketCount(totalDrawCount - totalApplyCount);

          // 既存の住所データを取得
          const shipInfo = await getApplyShipInfo(productId, user.uid);
          setShipInfo(shipInfo);
        } else {
          setRestTicketCount(0);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
        setValidProductId(false);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [productId, user, language]);

  // 各原画に応募した回数の合計を計算する関数
  const calculateTotalApplyCount = (
    applyGengaInfo: ApplyGengaInfoType[]
  ): number => {
    return applyGengaInfo.reduce(
      (total, genga) =>
        total +
        genga.items.reduce((sum, item) => sum + (item.applyCount || 0), 0),
      0
    );
  };

  //原画ごとの応募枚数の初期値を設定
  useEffect(() => {
    if (gengaInfo && applyGengaInfo) {
      const initialTicketCounts = gengaInfo.reduce<{ [key: string]: number }>(
        (acc, genga) => {
          // applyGengaInfoから対応するgengaのapplyCountを見つける
          const applyCount =
            applyGengaInfo
              .find((apply) => apply.productId === genga.productId)
              ?.items.find((item) => item.index === genga.index)?.applyCount ||
            0;

          // applyCountが見つかればそれを、見つからなければ0を初期値とする
          acc[genga.index] = applyCount;
          return acc;
        },
        {}
      );
      setTicketCounts(initialTicketCounts);
    } else if (gengaInfo) {
      // applyGengaInfoがない場合は全てのカウントを0に設定する
      const initialTicketCounts = gengaInfo.reduce<{ [key: string]: number }>(
        (acc, genga) => {
          acc[genga.index] = 0;
          return acc;
        },
        {}
      );
      setTicketCounts(initialTicketCounts);
    }
  }, [gengaInfo, applyGengaInfo]);

  // 応募する関数
  const handleSubmit = async () => {
    if (
      !productId ||
      !user ||
      !gengaInfo ||
      !ticketCounts ||
      !isEnd ||
      !stripeAddress
    ) {
      console.error("失敗しました");
      return;
    }

    setIsBtnLoading(true);

    const shipInfo: ApplyShipInfoType = {
      name: stripeAddress.name,
      phone: stripeAddress.phone || "",
      zip: stripeAddress.address.postal_code,
      prefecture: stripeAddress.address.state,
      city: stripeAddress.address.city,
      address: stripeAddress.address.line1,
      building: stripeAddress.address.line2 || "",
      productId: productId,
    };

    const result = await applyWChance(
      productId,
      user,
      gengaInfo,
      ticketCounts,
      shipInfo
    );

    if (result) {
      setShowPopup(true);
    } else {
      console.error("応募に失敗しました。");
    }

    setIsBtnLoading(false);
  };

  // チケット枚数を更新する関数
  const updateTicketCount = (
    gengaId: number,
    change: number,
    allChange: number
  ) => {
    if (change > 0 && restTicketCount > 0) {
      setTicketCounts((prevCounts) => ({
        ...prevCounts,
        [gengaId]: (prevCounts[gengaId] || 0) + change,
      }));
      setRestTicketCount((prevCount) => prevCount + allChange);
    } else if (
      change < 0 &&
      ticketCounts[gengaId] &&
      ticketCounts[gengaId] > 0
    ) {
      setTicketCounts((prevCounts) => ({
        ...prevCounts,
        [gengaId]: (prevCounts[gengaId] || 0) + change,
      }));
      setRestTicketCount((prevCount) => prevCount + allChange);
    }
  };

  // 原画モーダルを開く関数
  const openGengaModal = (index: GengaInfoType) => {
    setSelectedGengaInfo(index);
    setShowModal(true);
  };

  if (isLoading) {
    return (
      <>
        <Space height={64} />
        <Loading />
        <Space height={64} />
      </>
    );
  }

  if (!validProductId || !productData?.wchance.isOpen) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <title>
          {(productData && productData.productName) || ""}Wチャンス応募ページ
        </title>
      </Helmet>
      <Space height={16} />

      <Img
        imgPath={`${productId}/wchance.png`}
        alt="Wチャンス画像"
        className="border-2 border-white"
      />
      <Space height={16} />
      <h2>
        {(productData && productData.productName) || ""}
        <br />
        Wチャンス応募ページ
      </h2>
      <Space height={16} />
      <Link to={`/${productData?.productId || ""}`}>
        <BaseButton
          title="商品ページへ戻る"
          backgroundColor="green"
          textColor="#000"
        />
      </Link>
      <Space height={16} />
      <WChanceTable
        productData={productData}
        animeData={animeData}
        gengaInfo={gengaInfo}
        formattedStart={formattedStart}
        formattedEnd={formattedEnd}
      />
      <Space height={24} />
      <p>
        くじを引いた回数分、{productData.wchancePrize}
        があたるWチャンスに応募することができます！
        <br />
        応募期間ならいつでも応募内容の編集が可能です。
      </p>
      {/* {isLogin && (
        <BaseWhiteBg>
          <H2 text={isEnd ? "住所登録" : "登録済み住所"} blue={isEnd ? 3 : 4} />
          <StripeAddressElement product={productData} contacts={contacts} />
        </BaseWhiteBg>
      )} */}
      <Space height={24} />
      <BgH2 text="景品一覧" />
      <Space height={24} />
      {gengaInfo &&
        productData &&
        gengaInfo.map((genga) => (
          <ApplyItem
            key={genga.displayName}
            index={genga.index}
            displayName={genga.displayName}
            productId={genga.productId}
            applyCount={ticketCounts[genga.index] || 0}
            restTicketCount={restTicketCount}
            updateTicketCount={(change, allChange) =>
              updateTicketCount(genga.index, change, allChange)
            }
            onClick={() => openGengaModal(genga)}
            end={productData?.wchance.applyDeadline}
          />
        ))}
      <Space height={24} />
      <div
        className={`fixed ${
          isLogin ? "bottom-16" : "bottom-4"
        } z-[999] flex w-full max-w-[calc(100vw-32px)] justify-center min-[722px]:max-w-[690px]`}
      >
        {!isEnd ? (
          <DisabledButton title="応募期間終了" />
        ) : (
          <>
            {/* 残り枚数がある場合は、残り枚数を表示 */}
            {restTicketCount > 0 && (
              <Fukidashi>
                <p>残り{restTicketCount}枚</p>
              </Fukidashi>
            )}
            <Space height={8} />
            {/* 応募フォームが有効かつ残り枚数が0かつログイン済みの場合は、応募ボタンを有効化 */}
            {canSubmit && restTicketCount == 0 && isLogin ? (
              <BaseButton
                title="応募する"
                backgroundColor="blue"
                textColor="#000"
                onClick={handleSubmit}
                isLoading={isBtnLoading}
              />
            ) : (
              <DisabledButton title="応募する" />
            )}
          </>
        )}
      </div>

      <Space height={48} />
      {showModal && selectedGengaInfo && productData && (
        <GengaModal
          gengaInfo={selectedGengaInfo}
          animeData={animeData}
          onClose={() => setShowModal(false)}
        />
      )}
      <Popup
        isVisible={showPopup}
        onClose={() => setShowPopup(false)}
        message={
          "応募内容が保存されました！\n応募期間はいつでも応募内容を編集することができます。"
        }
      />
    </>
  );
};

export default WChance;
