import { useState, useEffect } from "react";

import { BaseButton } from "./Button/BaseButton";
import { Input } from "./Input";
import { Space } from "./Space";

type PasswordFormType = {
  productId: string | undefined;
  correctPassword: string | undefined;
  onPasswordCorrect: () => void;
};

export const PasswordForm = ({
  productId,
  correctPassword,
  onPasswordCorrect,
}: PasswordFormType) => {
  const [inputPasswordValue, setInputPasswordValue] = useState("");

  // パスワードの保存
  useEffect(() => {
    const storedPassword = localStorage.getItem(productId ?? "");
    if (storedPassword && storedPassword === correctPassword) {
      onPasswordCorrect();
    }
  }, [correctPassword, productId, onPasswordCorrect]);

  return (
    <>
      <Space height={64} />
      <h2>情報解禁前ページ</h2>
      <Space height={32} />
      <Input
        placeholder="パスワードを入力"
        value={inputPasswordValue}
        onChange={(e) => setInputPasswordValue(e.target.value)}
      />
      <Space height={32} />
      <BaseButton
        title="送信"
        onClick={() => {
          if (inputPasswordValue === correctPassword) {
            if (productId) {
              localStorage.setItem(productId, inputPasswordValue);
            }
            onPasswordCorrect();
          } else {
            alert("パスワードが違います");
          }
        }}
      />
      <Space height={64} />
    </>
  );
};
