import { logEvent } from "firebase/analytics";
import { memo } from "react";

import { analytics } from "../../firebase";

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;
  icon?: string;
  gaEvent?: string;
}

export const GoogleButton = memo(
  ({
    onClick,
    title,
    icon = require("../../assets/icon/google.webp"),
    gaEvent = `${title}${window.location.pathname + window.location.search}`,
  }: Props) => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick) {
        onClick(e);
      }

      if (gaEvent) {
        logEvent(analytics, gaEvent);
      }
    };
    return (
      <button
        className="relative h-[52px] w-full max-w-[320px] cursor-pointer rounded border border-gray bg-white p-2 text-center text-sm font-medium text-black shadow-[0_1px_1px_rgba(0,0,0,0.1)]"
        onClick={handleClick}
      >
        <img
          src={icon}
          alt="icon"
          className="absolute left-6 top-1/2 size-[24px] -translate-y-1/2"
        />
        <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
          {title}
        </span>
      </button>
    );
  }
);
