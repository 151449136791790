import { memo } from "react";

interface Props {
  onChange: () => void;
  gender: string;
}

export const Radio = memo(({ onChange, gender }: Props) => {
  return (
    <label className="relative h-6 cursor-pointer p-2 pl-9 text-[16px] text-black">
      <input
        type="radio"
        name="gender"
        value={gender}
        onChange={onChange}
        className="absolute left-0 top-1/2 size-6 -translate-y-1/2 cursor-pointer border border-gray accent-[#ff4026]"
      />
      {gender}
    </label>
  );
});
