import { StripeAddressForm } from "@gengakuji/common";
import { atom } from "jotai";

/**
 * AuthContext で使われていた型を流用。
 */
export type Auth =
  | {
      user: {
        uid: string;
        email: string | null;
        loginMethods: string[];
        getIdToken: () => Promise<string>;
      } | null;
      initialized: true;
    }
  | {
      user: null;
      initialized: false;
    };

/**
 * Auth の初期値
 */
export const authAtom = atom<Auth>({
  user: null,
  initialized: false,
});

export const addressAtom = atom<StripeAddressForm | null>(null);
