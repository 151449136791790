import {
  ProductCommonType,
  ProductTranslationsType,
  ProductType,
  SALE_STATUS,
  SaleStatus,
} from "@gengakuji/common";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { getDefaultStore } from "jotai";

import { languageAtom } from "../atoms";
import { db } from "../firebase";

const { get } = getDefaultStore();

export const convertStatusToLabel = (status: SaleStatus): string => {
  switch (status) {
    case SALE_STATUS.BEFORE_SALE:
      return "販売決定";
    case SALE_STATUS.NOW_ON_SALE:
      return "販売中";
    case SALE_STATUS.AFTER_SALE:
      return "販売終了";
  }
};

export const convertProductStatus = (
  open: Timestamp,
  start: Timestamp,
  end: Timestamp
): SaleStatus => {
  const now = Timestamp.now();
  if (open <= now && now < start) {
    return SALE_STATUS.BEFORE_SALE;
  } else if (start <= now && now <= end) {
    return SALE_STATUS.NOW_ON_SALE;
  } else {
    return SALE_STATUS.AFTER_SALE;
  }
};

// export const getProduct: (
//   productId: string,
// ) => Promise<ProductType | null> = async (productId: string) => {
//   const productRef = doc(db, "products", productId);
//   const productSnap = await getDoc(productRef);
//   if (productSnap.exists()) {
//     return productSnap.data() as ProductType;
//   } else {
//     return null;
//   }
// };

export const getProduct = async (
  productId: string
): Promise<ProductType | null> => {
  const productRef = doc(db, "products", productId);
  const productSnap = await getDoc(productRef);
  if (!productSnap.exists()) {
    return null;
  }

  const product = productSnap.data() as ProductType;

  const language = get(languageAtom);

  let selectedTranslation;
  // 現在の言語がavailableLanguagesに含まれている場合はその翻訳を使う
  if (product.availableLanguages.includes(language)) {
    selectedTranslation = product.translations[language];
  } else {
    // そうでなければtranslationsの最初のキーの翻訳を採用
    const translationKeys = Object.keys(product.translations);
    if (translationKeys.length > 0) {
      selectedTranslation = product.translations[translationKeys[0]];
    }
  }

  // もし翻訳が取得できた場合は、animeプロパティとして上書きして返す
  return {
    ...product,
    ...selectedTranslation,
  };
};

export const getProductList = async (): Promise<
  (ProductType & { saleStatus: SaleStatus })[]
> => {
  const now = Timestamp.now();
  const productsCollection = collection(db, "products");
  const q = query(
    productsCollection,
    where("open", "<=", now),
    orderBy("open", "desc")
  );
  const querySnapshot = await getDocs(q);

  let productList: (ProductType & { saleStatus: SaleStatus })[] = [];

  // jotaiの getDefaultStore から現在の言語を取得
  const { get } = getDefaultStore();
  const language = get(languageAtom);

  if (!querySnapshot.empty) {
    querySnapshot.forEach((docSnapshot) => {
      // Firestore上に保存されている共通データを取得
      const productCommon = docSnapshot.data() as ProductCommonType;

      let selectedTranslation = {} as ProductTranslationsType;

      // 現在の言語が利用可能な場合はその翻訳情報を利用
      if (productCommon.availableLanguages.includes(language)) {
        selectedTranslation = productCommon.translations[language];
      } else {
        // 利用可能な言語に含まれていなければ、translations の最初の要素を採用
        const keys = Object.keys(productCommon.translations);
        if (keys.length > 0) {
          selectedTranslation = productCommon.translations[keys[0]];
        }
      }

      // 共通データと選択した翻訳情報をマージして ProductType を生成
      const product: ProductType = {
        ...productCommon,
        ...selectedTranslation,
      };

      // saleStatus の算出（販売中、販売決定、販売終了などのステータスを変換）
      const saleStatus = convertProductStatus(
        product.open,
        product.start,
        product.end
      );

      productList.push({ ...product, saleStatus });
    });

    // saleStatus に基づいたカスタムソート（例: 販売中、販売決定、販売終了の順）
    productList.sort((a, b) => {
      const order = { 1: 0, 0: 1, 2: 2 };
      const orderA = order[a.saleStatus];
      const orderB = order[b.saleStatus];

      if (orderA !== orderB) {
        return orderA - orderB;
      }

      return b.start.toDate().getTime() - a.start.toDate().getTime();
    });
  }
  return productList;
};
