import { ProductType, AnimeType } from "@gengakuji/common";
import { useAtom } from "jotai";

import { countryCodeAtom } from "../../atoms";
import {
  convertScheduledShippingAt,
  convertshippingRequestDeadline,
} from "../../utils/index";

import { BaseTable } from "./BaseTable";

interface Props {
  productData: ProductType | null;
  animeData: AnimeType | null;
  firstBuy: boolean;
  user: any;
  formattedStart: string;
  formattedEnd: string;
}

export const ProductTable = ({
  productData,
  animeData,
  firstBuy,
  user,
  formattedStart,
  formattedEnd,
}: Props) => {
  const [countryCode] = useAtom(countryCodeAtom);

  const count = productData?.count ?? 0;
  const initialDiscountRate = productData?.initialDiscountRate ?? 0;

  // countryCodeが存在し、かつproductData.countryPricesに該当するキーが存在するか確認
  const isValidCountryCode =
    countryCode &&
    productData?.countryPrices &&
    countryCode in productData.countryPrices;

  const amount = isValidCountryCode
    ? productData?.countryPrices[countryCode]?.amount ?? 0
    : 0;
  const isDuplicate = productData?.isDuplicate;
  const postage = isValidCountryCode
    ? productData?.countryPrices[countryCode]?.postage ?? 0
    : 0;
  const shippingRequestDeadline = productData?.shippingRequestDeadline;
  const scheduledShippingAt = productData?.scheduledShippingAt;
  const copyright = animeData?.copyright ?? "";

  const STypeProducts = productData?.productTypes?.SType ?? [];
  const NormalTypeProducts = productData?.productTypes?.NormalType ?? [];
  const paymentMethods =
    isValidCountryCode &&
    productData?.countryPaymentsMethod &&
    countryCode in productData.countryPaymentsMethod
      ? productData.countryPaymentsMethod[countryCode] ?? []
      : [];

  // 割引価格計算
  const calcDiscountedPrice = (originalPrice: number, discount: number) => {
    const discountedPrice = Math.floor(
      (originalPrice * (100 - discount)) / 100
    );
    return new Intl.NumberFormat("ja-JP", {}).format(discountedPrice);
  };

  return (
    <BaseTable>
      <tbody>
        <tr>
          <td className="min-w-[88px] text-sm">商品数</td>
          <td>
            全<span className="text-red font-medium">{count}</span>
            種類
          </td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">商品内容</td>
          <td>
            S賞
            <ul>
              {STypeProducts.length > 0 ? (
                STypeProducts.map((val, index) => (
                  <li className="mb-2" key={`s-${index}`}>
                    <span>{val}</span>
                  </li>
                ))
              ) : (
                <>
                  <li className="mb-2" key="s-0">
                    <span>パラパラ原画集</span>
                  </li>
                  <li className="mb-2" key="s-1">
                    <span>動くデジタル原画</span>
                  </li>
                </>
              )}
            </ul>
            通常賞
            <ul>
              {NormalTypeProducts.length > 0 ? (
                NormalTypeProducts.map((val, index) => (
                  <li className="mb-2" key={`n-${index}`}>
                    <span>{val}</span>
                  </li>
                ))
              ) : (
                <>
                  <li className="mb-2" key="n-0">
                    <span>原画ポストカード</span>
                  </li>
                  <li className="mb-2" key="n-1">
                    <span>動くデジタル原画</span>
                  </li>
                </>
              )}
            </ul>
          </td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">価格</td>
          <td>
            {(firstBuy || !user) && initialDiscountRate > 0 ? (
              <>
                <span className="text-red font-medium">
                  初回限定{initialDiscountRate}%OFF!
                </span>
                <div>
                  <span className="mr-2 font-medium line-through">
                    <span className="text-xs">¥</span>
                    {new Intl.NumberFormat("ja-JP", {}).format(amount || 0)}
                    <span className="text-xs">（税込）</span>
                  </span>
                  <span className="text-red font-medium">
                    <span className="text-xs">¥</span>
                    {calcDiscountedPrice(amount!, initialDiscountRate)}
                    <span className="text-xs">（税込）</span>
                  </span>
                </div>
              </>
            ) : (
              <>
                <span>
                  <span className="text-xs">¥</span>
                  {new Intl.NumberFormat("ja-JP", {}).format(amount || 0)}
                  <span className="text-xs">（税込）</span>
                </span>
                <br />
                {/* 「確率タイプの場合」かつ「被りなしの場合」に表示 */}
                {!productData?.box?.isBox && !isDuplicate && (
                  <span className="text-red font-medium">10連で被りなし！</span>
                )}
              </>
            )}
          </td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">販売期間</td>
          <td>
            {formattedStart}
            <br />〜{formattedEnd}
          </td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">配送依頼期限</td>
          <td>
            {shippingRequestDeadline
              ? convertshippingRequestDeadline(shippingRequestDeadline)
              : "データが見つかりません。"}
          </td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">発送予定時期</td>
          <td>
            {scheduledShippingAt
              ? convertScheduledShippingAt(scheduledShippingAt)
              : "データが見つかりません。"}
          </td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">送料・梱包費</td>
          <td>
            <span className="text-xs">¥</span>
            {new Intl.NumberFormat("ja-JP", {}).format(postage || 0)}
            <span className="text-xs">（税込）</span>
            <br />
            <p className="text-xs">※発送ごと・全国一律・個数無制限</p>
          </td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">決済方法</td>
          <td>
            {paymentMethods && paymentMethods.length > 0 ? (
              paymentMethods.length > 1 ? (
                <ul>
                  {paymentMethods.map((val, index) => (
                    <li key={`payment-${index}`}>
                      <span>{val}</span>
                    </li>
                  ))}
                </ul>
              ) : (
                <span>{paymentMethods[0]}</span>
              )
            ) : (
              <ul>
                <li key="payment-1">
                  <span>クレジットカード</span>
                </li>
                <li key="payment-2">
                  <span>Apple Pay</span>
                </li>
                <li key="payment-3">
                  <span>Google Pay</span>
                </li>
              </ul>
            )}
          </td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">コピーライト</td>
          <td>(C) {copyright}</td>
        </tr>
      </tbody>
    </BaseTable>
  );
};
