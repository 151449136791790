import { ProductType, AnimeType } from "@gengakuji/common";

import { BaseTable } from "./BaseTable";

interface Props {
  productData: ProductType | null;
  animeData: AnimeType | null;
  gengaInfo: any[] | null;
  formattedStart: string;
  formattedEnd: string;
}

export const WChanceTable = ({
  productData,
  animeData,
  gengaInfo,
  formattedStart,
  formattedEnd,
}: Props) => {
  return (
    <BaseTable>
      <tbody>
        <tr>
          <td className="min-w-[88px] text-sm">景品</td>
          <td>{(productData && productData.wchancePrize) || ""}</td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">当選者数</td>
          <td>{gengaInfo?.length}人</td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">応募期間</td>
          <td>
            {formattedStart}
            <br />〜{formattedEnd}
          </td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">発送予定時期</td>
          <td>{productData && productData.wchanceScheduledShippingAt}</td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">送料</td>
          <td>無料</td>
        </tr>
        <tr>
          <td className="min-w-[88px] text-sm">コピーライト</td>
          <td>(C){(animeData && animeData.copyright) || ""}</td>
        </tr>
      </tbody>
    </BaseTable>
  );
};
