import {
  AnimeType,
  ProductType,
  CutInfoType,
  BoxType,
} from "@gengakuji/common";
import { useAtom } from "jotai";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams } from "react-router-dom";

import {
  isBuyBoxModalOpenAtom,
  isResetBoxLoadingAtom,
  isResetBoxModalOpenAtom,
  languageAtom,
} from "../atoms";
import { BaseViewer } from "../components/BaseViewer";
import { BaseButton } from "../components/Button/BaseButton";
import { FixedBottom } from "../components/FixedBottom";
import { Loading } from "../components/Loading";
import { BuyBoxModal } from "../components/Modal/BuyBoxModal";
import { ResetBoxModal } from "../components/Modal/ResetBoxModal";
import { PasswordForm } from "../components/PasswordForm";
import { Space } from "../components/Space";
import { getUser, getCutInfo, getProduct, getAnime } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";
import { fetchBoxContent } from "../utils/fetchBoxContent";
import { buyKuji, convertTimeStampToDate } from "../utils/index";
import { resetUserBox } from "../utils/resetBox";

const SampleViewer = memo(() => {
  const [language] = useAtom(languageAtom);
  const [firstBuy, setFirstBuy] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [images, setImages] = useState<string[] | null>(null);
  const [cutInfo, setCutInfo] = useState<CutInfoType | null>(null);
  const [productData, setProductData] = useState<ProductType | null>(null);
  const [animeData, setAnimeData] = useState<AnimeType | null>(null);
  const [boxData, setBoxData] = useState<BoxType | null>(null);
  const [mixedCount, setMixedCount] = useState(0);
  const { productId, cutId } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isCorrectPassword, setIsCorrectPassword] = useState(false);
  // Box購入モーダル関連
  const [isBuyBoxModalOpenState, setIsBuyBoxModalOpenState] = useAtom(
    isBuyBoxModalOpenAtom
  );

  // Boxリセットモーダル関連
  const [isResetBoxModalOpenState, setIsResetBoxModalOpenState] = useAtom(
    isResetBoxModalOpenAtom
  );
  const [isResetBoxLoadingState, setIsResetBoxLoadingState] = useAtom(
    isResetBoxLoadingAtom
  );

  const user = useAuthUser();

  // ▼▼▼ 1. リセット処理 ▼▼▼
  const handleResetBox = async () => {
    setIsResetBoxLoadingState(true);
    try {
      if (!productData?.productId) return;
      const newBox = await resetUserBox(productData.productId);
      setBoxData(newBox);
    } catch (error) {
      console.error("Boxリセットに失敗", error);
    } finally {
      setIsResetBoxLoadingState(false);
      setIsResetBoxModalOpenState(false);
      // 必要に応じて、リセット直後に購入モーダルを開くなら下記を呼ぶ
      setIsBuyBoxModalOpenState(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user?.uid && productId) {
        const userData = await getUser(user.uid);
        if (!userData?.boughtProductIds.includes(productId)) {
          setFirstBuy(true);
        }
      }
    };
    fetchData();
  }, [user, productId, language]);

  useEffect(() => {
    const fetchDataAndImages = async () => {
      if (!productId || !cutId) return;

      const cutInfoData = await getCutInfo(productId, cutId);
      const productData = await getProduct(productId);
      if (!productData) return;

      const animeData = await getAnime(productId);
      setCutInfo(cutInfoData);
      setProductData(productData);
      setAnimeData(animeData);

      const now = new Date();
      const open = new Date(productData.open.toDate());
      setIsOpen(now > open); // 情報解禁判定

      if (productData.box.isBox) {
        const boxData = await fetchBoxContent(productId);
        setBoxData(boxData);
      }

      setMixedCount(cutInfoData.mixedCount);
      setIsLoading(false);

      if (cutInfoData.mixedCount > 0) {
        const imageUrls = [];
        const baseUrl = `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${productId}/sample/${cutInfoData.index}/`;

        for (let i = 0; i < cutInfoData.mixedCount; i++) {
          imageUrls.push(`${baseUrl}${i}.png`);
        }

        setImages(imageUrls);
      }
    };

    fetchDataAndImages();
  }, [productId, cutId, language]);

  const handleBuyKuji = async (drawNumber: number) => {
    await buyKuji(drawNumber, productData, language);
  };

  if (isLoading) {
    return (
      <>
        <Space height={64} />
        <Loading />
        <Space height={64} />
      </>
    );
  }

  if (!isOpen && !isCorrectPassword) {
    return (
      <PasswordForm
        productId={productId}
        correctPassword={productData?.password}
        onPasswordCorrect={() => setIsCorrectPassword(true)}
      />
    );
  }

  return (
    <>
      <Helmet>
        <title>{`サンプル原画ビューア/${productId}/${cutInfo?.displayName}`}</title>
      </Helmet>
      <BaseViewer images={images} isLoading={isLoading} />
      <Link to={`/${productId}/sample`}>
        <BaseButton
          title="サンプル一覧へ"
          backgroundColor="green"
          textColor="#000"
        />
      </Link>
      <Space height={128} />
      <FixedBottom
        user={user}
        productData={productData}
        start={
          productData ? convertTimeStampToDate(productData.start) : new Date()
        }
        end={productData ? convertTimeStampToDate(productData.end) : new Date()}
        firstBuy={firstBuy}
        handleBuyKuji={handleBuyKuji}
        box={boxData}
        isLogin={false} //ここはfalseと直書きしておく
        animeData={animeData}
      />
      {/* モーダル表示部分 */}
      <>
        {isResetBoxModalOpenState && (
          <ResetBoxModal
            onConfirm={handleResetBox}
            isLoading={isResetBoxLoadingState}
            box={boxData}
          />
        )}
        {isBuyBoxModalOpenState && (
          <BuyBoxModal
            product={productData}
            leftCount={boxData?.leftCount ?? 1}
          />
        )}
      </>
    </>
  );
});

export default SampleViewer;
