import {
  ShipInfoType,
  AddressInfoType,
  RequestedShipInfo,
  ShippedInfo,
  shipState,
  SHIP_STATE,
  FirestoreDocument,
} from "@gengakuji/common";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

import { db } from "../firebase";

import { getProduct } from "./products";

export const shipInfoListener = async (
  uid: string,
  sessionId: string,
  callback: (data: ShipInfoType) => void
) => {
  const shipInfoRef = doc(db, "users", uid, "shipInfo", sessionId);

  const unsub = onSnapshot(shipInfoRef, (doc) => {
    callback(doc.data() as ShipInfoType);

    unsub();
  });

  return unsub;
};

// 発送済みタブの情報を取得し、shippedAtの昇順でソートする機能を組み込んだ関数
async function getShipInfoCommon(uid: string, shipState: shipState) {
  const shipInfoCollection = collection(db, "users", uid, "shipInfo");
  const q = query(shipInfoCollection, where("state", "==", shipState));
  const qs = await getDocs(q);

  const shopInfoPromise = qs.docs.map(async (doc) => {
    const data = {
      id: doc.id,
      ...doc.data(),
    } as FirestoreDocument<ShipInfoType>;

    // 商品情報
    const productData = await getProduct(data.productId);
    if (!productData) return null;

    // 住所情報
    const addressCollection = collection(db, doc.ref.path, "shipping_address");
    const addressInfo = await getDocs(addressCollection);
    const address = addressInfo.docs[0].data() as AddressInfoType;

    return { shipInfo: data, productData, address };
  });

  let shipInfo = (await Promise.all(shopInfoPromise)).filter(
    (info) => info !== null
  );

  //配送依頼済みタブの場合は、配送依頼日時の降順でソート
  if (shipState === SHIP_STATE.REQUEST_SHIP) {
    shipInfo = shipInfo.sort((a, b) => {
      if (a!.shipInfo.createdAt && b!.shipInfo.createdAt) {
        return (
          b!.shipInfo.createdAt.toDate().getTime() -
          a!.shipInfo.createdAt.toDate().getTime()
        );
      }
      return 0;
    });
    //発送済みタブの場合は、発送日時の降順でソート
  } else if (shipState === SHIP_STATE.SHIPPED) {
    shipInfo = shipInfo.sort((a, b) => {
      if (a!.shipInfo.shippedAt && b!.shipInfo.shippedAt) {
        return (
          b!.shipInfo.shippedAt.toDate().getTime() -
          a!.shipInfo.shippedAt.toDate().getTime()
        );
      }
      return 0;
    });
  }

  return shipInfo as RequestedShipInfo[];
}

//配送依頼済みタブ
export const getShipInfo = async (uid: string) => {
  const requestShipInfo = await getShipInfoCommon(uid, SHIP_STATE.REQUEST_SHIP);
  const packedShipInfo = await getShipInfoCommon(uid, SHIP_STATE.PACKED);

  const allShipInfo = [
    ...(requestShipInfo as RequestedShipInfo[]),
    ...(packedShipInfo as RequestedShipInfo[]),
  ];

  // createdAt の降順でソート
  return allShipInfo.sort((a, b) => {
    if (a.shipInfo.createdAt && b.shipInfo.createdAt) {
      return (
        b.shipInfo.createdAt.toDate().getTime() -
        a.shipInfo.createdAt.toDate().getTime()
      );
    }
    return 0;
  });
};

//発送済みタブ
export const getShippedInfo = async (uid: string) => {
  return getShipInfoCommon(uid, SHIP_STATE.SHIPPED) as Promise<ShippedInfo[]>;
};
