import { useAtom } from "jotai";

import { authAtom } from "../atoms";

/**
 * 1. Auth全体を返すフック
 */
export const useAuth = () => {
  const [auth] = useAtom(authAtom);
  return auth;
};

/**
 * 2. user だけを返すフック
 */
export const useAuthUser = () => {
  return useAuth().user;
};

/**
 * 3. initialized だけを返すフック
 */
export const useAuthInitialized = () => {
  return useAuth().initialized;
};
