import { memo, useState, useEffect } from "react";

interface Props {
  imgPath: string;
  alt: string;
  width?: string;
  className?: string;
  priority?: boolean;
  sizes?: string;
}

export const Img = memo(
  ({
    imgPath,
    alt,
    width = "100%",
    className,
    priority = false,
    sizes = "100vw",
  }: Props) => {
    const bucketUrl = `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}`;
    const src = imgPath ? `${bucketUrl}/${imgPath}` : "";
    const [isLoaded, setIsLoaded] = useState(priority);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
      if (!imgPath) {
        setIsError(true);
        return;
      }

      if (priority) {
        setIsLoaded(true);
        return;
      }

      // Preload the image
      const img = new Image();
      img.src = src;
      img.onload = () => setIsLoaded(true);
      img.onerror = () => {
        setIsError(true);
      };
    }, [src, priority, imgPath]);

    if (!imgPath || isError) {
      return (
        <div
          className={`bg-gray-200 flex items-center justify-center ${className}`}
          style={{ width, aspectRatio: "1/1" }}
        >
          <span className="text-sm text-gray-500">
            画像を読み込めませんでした
          </span>
        </div>
      );
    }

    return (
      <img
        src={src}
        alt={alt}
        width={width}
        className={className}
        loading={priority ? "eager" : "lazy"}
        onLoad={() => setIsLoaded(true)}
        onError={() => setIsError(true)}
        decoding={priority ? "sync" : "async"}
        sizes={sizes}
      />
    );
  }
);
