import { AvailableShipInfo } from "@gengakuji/common";
import { useAtom } from "jotai";
import { memo } from "react";

import { countryCodeAtom, languageAtom } from "../../atoms";
import {
  convertScheduledShippingAt,
  convertshippingRequestDeadline,
} from "../../utils/index";
import { BaseButton } from "../Button/BaseButton";
import { Img } from "../Img";
import { Space } from "../Space";

import { BaseCard } from "./BaseCard";

interface Props {
  availableShipInfo: AvailableShipInfo;
  handleShowModal: () => void; // ボタンクリック時のコールバックを追加
  chooseOneProduct: (item: AvailableShipInfo) => void;
}

export const RequestCard = memo(
  ({ availableShipInfo, handleShowModal, chooseOneProduct }: Props) => {
    const [language] = useAtom(languageAtom);

    // "product" フィールド + その他のデータを分割代入
    const {
      product, // 入れ子の product (ProductType)
      numOfAvailableDeliveries,
      // ▼ 追加配送が無料かどうかのフラグ
      existingShipInfoId,
    } = availableShipInfo;

    // product の中身を取り出す
    const { productId, shippingRequestDeadline, countryPrices } = product;

    const [countryCode] = useAtom(countryCodeAtom);

    const postage = countryCode ? countryPrices[countryCode]?.postage || 0 : 0;

    const handleRequestClick = () => {
      chooseOneProduct(availableShipInfo);
      handleShowModal();
    };

    return (
      <BaseCard>
        <Space height={16} />
        <Img imgPath={`${productId}/ogp.png`} alt="商品画像" />
        <Space height={16} />
        <h3>{product.translations[language].productName}</h3>
        <Space height={8} />
        <p>全{numOfAvailableDeliveries}点</p>
        <Space height={16} />
        <div className="text-left">
          <label>配送依頼期限</label>
          <p> {convertshippingRequestDeadline(shippingRequestDeadline)}</p>
          <Space height={16} />
          <label>発送予定時期</label>
          <p>
            {convertScheduledShippingAt(
              product.translations[language].scheduledShippingAt
            )}
          </p>
          <Space height={16} />
          <label>送料・梱包費</label>
          {/* 追加配送フラグが立っていれば無料表示 */}
          <p>
            {existingShipInfoId ? (
              "無料"
            ) : (
              <>
                <span className="text-xs">¥</span>
                {new Intl.NumberFormat("ja-JP", {}).format(postage)}
                <span className="text-xs">（税込）</span>
              </>
            )}
          </p>
          <p className="text-xs text-[#999999]">
            ※発送ごと・全国一律・個数無制限
          </p>
        </div>
        <Space height={16} />
        <BaseButton
          onClick={handleRequestClick}
          title={existingShipInfoId ? "追加配送依頼へ進む" : "配送依頼へ進む"}
        />
        <Space height={16} />
      </BaseCard>
    );
  }
);
