import { BoxType } from "@gengakuji/common";

import { getUserIdToken } from "../firebase/auth";

/**
 * boxes/{uid}/{productId} から最新の BoxType を取得し、
 * 在庫データが無い場合は初期在庫データを返すエンドポイントを呼び出す。
 *
 * @param productId - 対象の productId
 * @returns BoxType を含むオブジェクト { box: BoxType } or エラー
 */
export const fetchBoxContent = async (productId: string) => {
  try {
    // ユーザーの ID トークンを取得
    const idToken = await getUserIdToken();
    if (!idToken) {
      console.error("ユーザー認証に失敗しました。");
    }

    // POST 用のボディ
    const body = { productId };

    // fetch 実行 (process.env.REACT_APP_GET_BOX_CONTENT: Cloud Functions エンドポイントURL)
    const response = await fetch(process.env.REACT_APP_GET_BOX_CONTENT!, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      const errText = await response.text();
      console.error(
        `Box在庫データの取得に失敗しました。ステータス:${response.status}, 内容:${errText}`
      );
    }

    // 成功時は { box: BoxType } が返ってくる想定
    const data = (await response.json()) as { box: BoxType };
    return data.box;
  } catch (error) {
    console.error("fetchBoxContent エラー:", error);
    return null;
  }
};
