import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  // プラグインを適用
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)

  // 設定
  .init({
    // デフォルトで使用する言語
    fallbackLng: "ja",

    // 実装段階で翻訳キーをコンソール出力したい場合
    // debug: process.env.NODE_ENV === "development",

    // i18next-http-backend でどのように翻訳ファイルを取得するか
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json", // 例: public/locales/en/translation.json 等
    },

    interpolation: {
      // React で使用する場合は XSS 対策を i18next 側でしない設定が一般的
      escapeValue: false,
    },

    // react-i18next の設定
    react: {
      useSuspense: false, // サスペンスを使わない場合は false
    },
  });

export default i18n;
