import { AvailableShipInfo, ContactType } from "@gengakuji/common";
import { useAtom } from "jotai";
import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { addressAtom, countryCodeAtom, languageAtom } from "../../atoms";
import { getUserIdToken } from "../../firebase/index";
import { BaseButton } from "../Button/BaseButton";
import { DisabledButton } from "../Button/DisabledButton";
import { ColorSpan } from "../ColorSpan";
import { H2 } from "../H2";
import { Img } from "../Img";
import { Space } from "../Space";
import StripeAddressElement from "../StripeAddressElement";

import { BaseModal } from "./BaseModal";

interface Props {
  productData: AvailableShipInfo | null;
  isLoadingRequest: boolean;
  onClose: () => void;
  contacts: ContactType[];
}

export const RequestModal = memo(
  ({ productData, onClose, contacts }: Props) => {
    const [language] = useAtom(languageAtom);
    const [stripeAddress] = useAtom(addressAtom);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const [countryCode] = useAtom(countryCodeAtom);

    const canSubmit = !!stripeAddress;

    /**
     * 追加配送(無料) → 既存のShipInfoに items を追加する
     * stripeを噛ませない
     */
    const handleAdditionalShip = async () => {
      if (!productData?.existingShipInfoId) return;
      setIsLoading(true);
      try {
        const idToken = await getUserIdToken();
        const body = {
          existingShipInfoId: productData.existingShipInfoId,
        };
        const res = await fetch(process.env.REACT_APP_ADDITIONAL_SHIP!, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });
        const data = await res.json();
        setIsLoading(false);

        if (data.existingShipInfoId) {
          navigate("/complete?session_id=" + data.existingShipInfoId);
          return;
        }
      } catch (err) {
        console.error(err);
        setIsLoading(false);
        console.error("エラーが発生しました。");
      }
    };

    /**
     * 通常配送(送料あり) → Stripe Checkout
     */
    const handleSubmit = async () => {
      setIsLoading(true);

      if (!productData) {
        setIsLoading(false);
        return;
      }
      // 「既存ShipInfoがある」なら無料でStripeスキップ
      if (productData.existingShipInfoId) {
        await handleAdditionalShip();
        return;
      }
      // それ以外は従来通りStripe
      const idToken = await getUserIdToken();

      const body = {
        language: language,
        name: stripeAddress?.name,
        phone: stripeAddress?.phone,
        zip: stripeAddress?.address.postal_code,
        prefecture: stripeAddress?.address.state,
        city: stripeAddress?.address.city,
        address: stripeAddress?.address.line1,
        building: stripeAddress?.address.line2,
        country: stripeAddress?.address.country,
        productId: productData.product.productId,
        event: "REQUEST_SHIP",
        isNewAddress: stripeAddress?.isNewAddress,
      };

      const res = await fetch(
        process.env.REACT_APP_CREATE_REQUEST_SHIP_CHECKOUT_SESSION!,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );

      const data = await res.json();
      window.location.replace(data.redirectURL);
      setIsLoading(false);
    };

    return (
      <>
        <BaseModal bgColor="bg-white" showCross={false}>
          <H2 text="配送依頼" blue={3} />
          <>
            <Img
              imgPath={`${productData?.product.productId}/ogp.png`}
              alt="商品画像"
            />
            <Space height={16} />
            <h3>{productData?.product.translations[language].productName}</h3>
            <p>全{productData?.numOfAvailableDeliveries}点</p>
          </>

          <Space height={16} />

          <>
            {/* ここから商品を表示 */}
            {productData?.items && productData.items.length > 0 && (
              <div className="mt-4 space-y-4">
                {productData.items.map((item) => (
                  <div
                    key={item.cutId}
                    className="flex items-center justify-between"
                  >
                    {/* 左側の画像ブロック */}
                    <div className="w-1/2">
                      <Img
                        imgPath={`${productData.product.productId}/cover/${item.index}.png`}
                        alt={item.name}
                        className="w-full shadow-md"
                      />
                    </div>
                    {/* 右側の情報ブロック */}
                    <div className="w-1/2 pl-4">
                      <p className="text-left text-base">{item.name}</p>
                      <p className="text-left text-base">数量: {item.amount}</p>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* ここまで商品を表示 */}
          </>
          <Space height={32} />
          <>
            {productData?.existingShipInfoId ? (
              <div className="text-left">
                <label>配送先</label>
                <p>設定済み</p>
              </div>
            ) : (
              <div className="text-left">
                <label>配送先</label>
                <StripeAddressElement
                  product={productData?.product!}
                  contacts={contacts}
                />
              </div>
            )}
          </>
          <Space height={16} />
          <>
            <div className="text-left">
              <label>送料・梱包費</label>
              <p>
                {productData?.existingShipInfoId ? (
                  "無料"
                ) : (
                  <>
                    {countryCode ? (
                      <>
                        <span className="text-xs">¥</span>
                        {new Intl.NumberFormat("ja-JP", {}).format(
                          productData?.product.countryPrices[countryCode]
                            ?.postage || 0
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    <span className="text-xs">（税込）</span>
                  </>
                )}
              </p>
            </div>
            <Space height={44} />
          </>
          <>
            {productData?.existingShipInfoId ? (
              <BaseButton
                title="追加配送依頼を完了する"
                backgroundColor="#ff4127"
                textColor="#fff"
                onClick={handleSubmit}
                isLoading={isLoading}
              />
            ) : (
              <>
                {canSubmit ? (
                  <BaseButton
                    title="配送依頼進む"
                    backgroundColor="#ff4127"
                    textColor="#fff"
                    onClick={handleSubmit}
                    isLoading={isLoading}
                  />
                ) : (
                  <DisabledButton title="配送依頼する" />
                )}
              </>
            )}

            <Space height={8} />
            {!productData?.existingShipInfoId && (
              <>
                <p className="text-xs text-[#999999]">決済画面に遷移します</p>
                <Space height={20} />
              </>
            )}
          </>
          <Space height={24} />
          <div onClick={onClose}>
            <ColorSpan>配送管理へ戻る</ColorSpan>
          </div>
          <Space height={44} />
        </BaseModal>
      </>
    );
  }
);
