import { BoxType } from "@gengakuji/common";

import { getUserIdToken } from "../firebase/auth";

/**
 * ボックスをリセットする関数
 * @param productId - リセットしたい productId
 */
export const resetUserBox = async (productId: string) => {
  try {
    // ユーザーの ID トークンを取得
    const idToken = await getUserIdToken();
    if (!idToken) {
      console.error("IDトークンが取得できませんでした。");
      return null;
    }

    // リクエストのボディを準備
    const body = { productId };

    // fetch で POST リクエストを送信
    const response = await fetch(process.env.REACT_APP_RESET_BOX!, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${idToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      // ステータスコードがエラーの場合
      const errorText = await response.text();
      console.error("リセットに失敗しました:", errorText);
      return null;
    }
    // 成功時のレスポンスを JSON として受け取る
    const data = await response.json();

    // 必要に応じて newBoxNumber などを使って処理する
    return data.box as BoxType;
  } catch (e) {
    console.error("リセットのリクエスト中にエラーが発生:", e);
    return null;
  }
};
