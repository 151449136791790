import { useAtom } from "jotai";
import { useEffect } from "react";

import { countryCodeAtom } from "../atoms";

const useCountryCode = () => {
  const [, setCountryCode] = useAtom(countryCodeAtom);

  useEffect(() => {
    const fetchCountryCode = async () => {
      try {
        const response = await fetch("https://api.country.is/");
        if (!response.ok) {
          throw new Error("ネットワークエラー");
        }
        const data = await response.json();
        setCountryCode(data.country);
      } catch (error) {
        console.error("国コードの取得に失敗しました:", error);
        setCountryCode(null);
      }
    };

    fetchCountryCode();
  }, [setCountryCode]);
};

export default useCountryCode;
