import {
  GengaInfoType,
  GengaInfoCommonType,
  GengaInfoTranslationsType,
} from "@gengakuji/common";
import { collection, getDocs, query } from "firebase/firestore";
import { getDefaultStore } from "jotai";

import { languageAtom } from "../atoms";
import { db } from "../firebase";

/**
 * 指定された言語に対応する翻訳データを取得し、共通データに上書きして返す
 */
const mergeGengaTranslation = (
  data: GengaInfoCommonType,
  language: string
): GengaInfoType => {
  let selectedTranslation: GengaInfoTranslationsType =
    {} as GengaInfoTranslationsType;
  // 現在の言語が translations に存在する場合はその翻訳情報を採用
  if (data.translations && data.translations[language]) {
    selectedTranslation = data.translations[language];
  } else {
    // 存在しなければ translations の最初の要素を採用
    const keys = Object.keys(data.translations || {});
    if (keys.length > 0) {
      selectedTranslation = data.translations[keys[0]];
    }
  }
  return { ...data, ...selectedTranslation };
};

/**
 * 全ての原画情報を取得し、現在の言語に対応する翻訳情報で上書きした上で、indexに基づいてソートする
 */
export const getGengaInfo = async (
  productId: string
): Promise<GengaInfoType[]> => {
  const { get } = getDefaultStore();
  const language = get(languageAtom);
  const gengasCollectionRef = collection(db, "genga_info", productId, "gengas");
  const q = query(gengasCollectionRef);
  const querySnapshot = await getDocs(q);
  const gengaInfoDocs: GengaInfoType[] = [];

  querySnapshot.forEach((docSnap) => {
    const data = docSnap.data() as GengaInfoCommonType;
    // 翻訳情報で上書きしたデータを配列に追加
    gengaInfoDocs.push(mergeGengaTranslation(data, language));
  });

  // indexに基づいて昇順にソート
  return gengaInfoDocs.sort((a, b) => a.index - b.index);
};
