import {
  CutInfoCommonType,
  CutInfoTranslationsType,
  CutInfoType,
  FirestoreDocument,
  KujiType,
} from "@gengakuji/common";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { getDefaultStore } from "jotai";

import { languageAtom } from "../atoms";
import { db } from "../firebase";

const { get } = getDefaultStore();

/**
 * 指定された言語に対応する翻訳データを取得し、共通データに上書きして返す
 */
const mergeTranslation = (
  data: CutInfoCommonType,
  language: string
): CutInfoType => {
  let selectedTranslation: CutInfoTranslationsType =
    {} as CutInfoTranslationsType;
  // 現在の言語が translations に存在する場合はその翻訳情報を採用
  if (data.translations && data.translations[language]) {
    selectedTranslation = data.translations[language];
  } else {
    // 存在しなければ translations の最初の要素を採用
    const keys = Object.keys(data.translations || {});
    if (keys.length > 0) {
      selectedTranslation = data.translations[keys[0]];
    }
  }
  return { ...data, ...selectedTranslation };
};

/**
 * 指定した productId, cutId のデータを取得し、現在の言語に対応する翻訳データで上書きして返す
 */
export const getCutInfo = async (
  productId: string,
  cutId: string
): Promise<CutInfoType> => {
  const cutInfoRef = doc(db, "cut_info", productId, "cuts", cutId);
  const snap = await getDoc(cutInfoRef);
  if (!snap.exists()) {
    throw new Error("CutInfo not found");
  }
  const data = snap.data() as CutInfoCommonType;
  const language = get(languageAtom);
  return mergeTranslation(data, language);
};

/**
 * 指定した productId, kujiType のデータ一覧を取得し、現在の言語に対応する翻訳データで上書きして返す
 */
export const getTypedCutInfo = async (
  productId: string,
  kujiType: KujiType
): Promise<CutInfoType[]> => {
  const cutInfoCollection = collection(db, "cut_info", productId, "cuts");
  const q = query(cutInfoCollection, where("type", "==", kujiType));
  const qs = await getDocs(q);
  const language = get(languageAtom);

  const cutInfos = await Promise.all(
    qs.docs.map(async (docSnap) => {
      const data = docSnap.data() as CutInfoCommonType;
      return mergeTranslation(data, language);
    })
  );
  // index に基づいて昇順にソート
  return cutInfos.sort((a, b) => a.index - b.index);
};

/**
 * 指定した productId の全 cut_info を取得し、現在の言語に対応する翻訳データで上書きした上で、id を付与して返す
 */
export const getCutInfos = async (
  productId: string
): Promise<FirestoreDocument<CutInfoType>[]> => {
  const cutInfoCollection = collection(db, "cut_info", productId, "cuts");
  const qs = await getDocs(cutInfoCollection);
  const language = get(languageAtom);

  const cutInfos = await Promise.all(
    qs.docs.map(async (docSnap) => {
      const data = docSnap.data() as CutInfoCommonType;
      return { ...mergeTranslation(data, language), id: docSnap.id };
    })
  );
  // index に基づいて昇順にソート
  return cutInfos.sort((a, b) => a.index - b.index);
};
