import { ProductType } from "@gengakuji/common";
import { ShipInfoType, EmailType } from "@gengakuji/common";
import { useAtom } from "jotai";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";

import { languageAtom } from "../atoms";
import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { Border } from "../components/Border";
import { BaseButton } from "../components/Button/BaseButton";
import { H2 } from "../components/H2";
import { Img } from "../components/Img";
import { Loading } from "../components/Loading";
import { Space } from "../components/Space";
import { getEmail, getProduct, shipInfoListener } from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";

const title = "配送依頼完了";

const Complete = memo(() => {
  const [language] = useAtom(languageAtom);
  const [email, setEmail] = useState<EmailType | null>(null);
  const [productData, setProductData] = useState<ProductType | null>(null);
  const [shipInfo, setShipInfo] = useState<ShipInfoType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const user = useAuthUser();
  const navigate = useNavigate();

  //配送情報と商品情報の取得
  useEffect(() => {
    if (user) {
      const fetchStripeSession = async () => {
        const sessionId = new URLSearchParams(window.location.search).get(
          "session_id"
        );
        if (!sessionId) {
          navigate("/404");
          return;
        }
        if (!user?.uid) {
          navigate("/404");
          return;
        }

        const unsub = shipInfoListener(user.uid, sessionId, async (data) => {
          const pData = await getProduct(data.productId);
          setProductData(pData);
          setShipInfo(data);
        });
        return unsub;
      };
      setIsLoading(false);
      fetchStripeSession();
    }
  }, [user, language]);

  //メール情報取得
  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        if (user) {
          const emailDoc = await getEmail(user.uid);
          setEmail(emailDoc);
        } else {
          navigate("/404");
        }
      };
      fetchUserData();
    }
  }, [user]);

  if (isLoading) {
    return (
      <BaseWhiteBg>
        <Space height={64} />
        <Loading topColor="#666" borderColor="rgba(0, 0, 0, 0.3)" />
        <Space height={64} />
      </BaseWhiteBg>
    );
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <BaseWhiteBg>
        <H2 text={title} blue={3} />
        <p className="text-red text-2xl">配送依頼が完了しました！</p>
        <Space height={32} />
        {productData ? (
          <Img
            imgPath={`${productData.productId}/ogp.png`}
            alt="商品画像"
            priority={true}
          />
        ) : null}
        <Space height={32} />
        {productData ? (
          <>
            <h3>{productData.translations[language].productName}</h3>
            <p>全{shipInfo?.numOfAvailableShip}点</p>
          </>
        ) : null}
        <Space height={32} />
        <Border color="#cacaca" />
        <Space height={32} />
        <label>発送予定時期</label>
        <p>
          {productData &&
          shipInfo &&
          shipInfo.scheduledShippingAtIndex !== undefined &&
          productData.scheduledShippingAt[shipInfo.scheduledShippingAtIndex]
            ? productData.scheduledShippingAt[shipInfo.scheduledShippingAtIndex]
            : shipInfo?.FinalShippingDate}
        </p>
        <Space height={32} />
        <label>配送管理番号</label>
        <p>{shipInfo?.customReceiptNumber}</p>
        <p className="text-xs text-[#999999]">
          配送管理番号は配送管理ページでも確認できます。
        </p>
        <Space height={44} />
        <p>
          発送されたら{email?.email}に
          <br />
          メールが届きます。
        </p>
        <Space height={44} />
        <Link to="/">
          <BaseButton
            title="Topページへ"
            backgroundColor="green"
            textColor="#000"
          />
        </Link>
        <Space height={44} />
      </BaseWhiteBg>
    </>
  );
});

export default Complete;
