import { logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  matchPath,
} from "react-router-dom";

import { BottomMenu } from "./components/BottomMenu";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { ScrollToTop } from "./components/ScrollToTop";
import { Space } from "./components/Space";
import { analytics } from "./firebase";
import { useAuthUser } from "./hooks/useAuth";
import useCountryCode from "./hooks/useCountryCode";
import { useFirebaseAuthInit } from "./hooks/useFirebaseAuth";
import { useI18nSync } from "./hooks/useI18nSync";
import Collection from "./pages/Collection";
import CollectionList from "./pages/CollectionList";
import Complete from "./pages/Complete";
import Contact from "./pages/Contact";
import HistoryList from "./pages/HistoryList";
import HistoryPage from "./pages/HistoryPage";
import Login from "./pages/Login";
import MyPage from "./pages/MyPage";
import NotFound from "./pages/NotFound";
import Product from "./pages/Product";
import ProductList from "./pages/ProductList";
import Register from "./pages/Register";
import Result from "./pages/Result";
import SampleCollection from "./pages/SampleCollection";
import SampleViewr from "./pages/SampleViewer";
import Shipping from "./pages/Shipping";
import ShippingDetail from "./pages/ShippingDetail";
import Viewer from "./pages/Viewer";
import WChance from "./pages/WChance";
import { usePasswordPrompt } from "./utils";

const Layout = () => {
  const location = useLocation();

  const [isLogin, setIsLogin] = useState(false);
  const user = useAuthUser();

  //ログイン判定
  useEffect(() => {
    setIsLogin(!!user?.uid);
  }, [user]);

  useEffect(() => {
    logEvent(analytics, "page_view", {
      page_path: location.pathname + location.search,
    });
  }, [location]);

  const isViewerPage = matchPath(
    "/collection/:productId/:cutId",
    location.pathname
  );
  const isSampleViewerPage = matchPath(
    "/:productId/sample/:cutId",
    location.pathname
  );
  const hideHeaderFooter = isViewerPage || isSampleViewerPage;

  return (
    <>
      {!hideHeaderFooter && <Header />}
      <div className="m-0 mx-auto min-h-screen max-w-3xl p-0 px-4 text-center">
        {/* ヘッダーの高さ分だけ下にずらす */}
        {!hideHeaderFooter && <Space height={56} />}
        <Routes>
          <Route path="/" element={<ProductList />} />
          <Route path="/:productId" element={<Product />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/result" element={<Result />} />
          <Route path="/collection" element={<CollectionList />} />
          <Route path="/collection/:productId" element={<Collection />} />
          <Route path="/mypage" element={<MyPage />} />
          <Route path="/shipping" element={<Shipping />} />
          <Route
            path="/shipping/:customReceiptNumber"
            element={<ShippingDetail />}
          />
          <Route path="/collection/:productId/viewer" element={<Viewer />} />
          <Route path="/complete" element={<Complete />} />
          <Route path="/mypage/history" element={<HistoryList />} />
          <Route path="/:productId/sample" element={<SampleCollection />} />
          <Route path="/:productId/sample/:cutId" element={<SampleViewr />} />
          <Route
            path="/mypage/history/:customReceiptNumber"
            element={<HistoryPage />}
          />
          <Route path="/:productId/wchance" element={<WChance />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
      {!hideHeaderFooter && <Footer />}
      {!hideHeaderFooter && isLogin && <BottomMenu />}
    </>
  );
};

const App = () => {
  // ユーザーデータを初期化
  useFirebaseAuthInit();
  // jotaiの言語をi18nと同期するhooks
  useI18nSync();
  // 国コードをjotaiにセットするhooks
  useCountryCode();

  //画像保存不可処理
  useEffect(() => {
    const imageElements = document.querySelectorAll("img");
    imageElements.forEach((img) => {
      img.addEventListener("touchstart", disableLongPress);
      img.addEventListener("contextmenu", disableRightClick);
    });

    return () => {
      imageElements.forEach((img) => {
        img.removeEventListener("touchstart", disableLongPress);
        img.removeEventListener("contextmenu", disableRightClick);
      });
    };
  }, []);

  const disableLongPress = (e: TouchEvent) => {
    e.preventDefault();
  };

  const disableRightClick = (e: MouseEvent) => {
    e.preventDefault();
  };

  //developmentの環境の場合にパスワードの入力を求める
  const hasAccess =
    process.env.REACT_APP_ENV === "development"
      ? usePasswordPrompt(process.env.REACT_APP_PASSWORD || "")
      : true;

  if (!hasAccess) {
    return;
  }

  return (
    <>
      <HelmetProvider>
        <Router>
          <ScrollToTop />
          <Layout />
        </Router>
      </HelmetProvider>
    </>
  );
};

export default App;
