import {
  AnimeType,
  ProductType,
  CollectionType,
  CutInfoType,
  FirestoreDocument,
} from "@gengakuji/common";
import { logEvent } from "firebase/analytics";
import { useAtom } from "jotai";
import { memo, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useNavigate } from "react-router-dom";

import { languageAtom } from "../atoms";
import { BaseButton } from "../components/Button/BaseButton";
import { Img } from "../components/Img";
import { Loading } from "../components/Loading";
import { Share } from "../components/Share";
import { Space } from "../components/Space";
import { analytics } from "../firebase";
import {
  getCollection,
  getCutInfos,
  getProduct,
  getUser,
  getAnime,
  sortCollectionByIndex,
} from "../firebase/index";
import { useAuthUser } from "../hooks/useAuth";

const title = "コレクション";

const Collection = memo(() => {
  const [language] = useAtom(languageAtom);

  const { productId } = useParams();
  const user = useAuthUser();
  const [collections, setCollections] = useState<
    FirestoreDocument<CollectionType>[]
  >([]);
  const [cutInfos, setCutInfos] = useState<FirestoreDocument<CutInfoType>[]>(
    []
  );
  const [count, setCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState<ProductType | null>(null);
  const [animeData, setAnimeData] = useState<AnimeType | null>(null);
  // productIdのコレクションを取得
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !productId) {
        navigate("/login?from=collection");
        return;
      }

      const userData = await getUser(user.uid);
      if (!userData) {
        navigate("/login?from=collection");
        return;
      }

      const productData = await getProduct(productId);
      const animeData = await getAnime(productId);
      if (productData && animeData) {
        setCount(productData.count);
        setProductData(productData);
        setAnimeData(animeData);
      }

      // コレクションデータを取得
      const collectionData = await getCollection(user.uid, productId);
      const sortedData = sortCollectionByIndex(collectionData);

      // 商品のカットデータを取得
      const cutInfosData = await getCutInfos(productId);
      setCutInfos(cutInfosData);

      setCollections(sortedData);
      setIsLoading(false);
    };

    fetchData();
  }, [user, productId, navigate, language]);

  const isWon = (cutInfo: CutInfoType) => {
    let result = collections.some(
      (collection) => collection.cutInfo.cutId === String(cutInfo.index)
    );
    return result;
  };

  return (
    <>
      <Helmet>
        <title>
          {title}/{productId}
        </title>
      </Helmet>
      <>
        {isLoading ? (
          <>
            <Space height={64} />
            <Loading />
            <Space height={64} />
          </>
        ) : cutInfos ? (
          cutInfos.length > 0 ? (
            <>
              <Space height={24} />
              <>
                <p>
                  所持数： {collections ? collections.length : 0} / {count}
                </p>
                <Space height={8} />
                {collections && collections.length === count ? (
                  <p className="text-red font-bold">コンプリート達成！</p>
                ) : (
                  <p>
                    あと
                    <span className="text-red font-bold">
                      {count - (collections ? collections.length : 0)}枚
                    </span>
                    でコンプリート！
                  </p>
                )}
              </>
              <Space height={24} />
              <div className="mx-auto grid grid-cols-2 gap-4">
                {cutInfos.map((cutInfo, index) => {
                  // それぞれのカットを所有しているか判定
                  const owned = isWon(cutInfo);
                  const imgPath = `${cutInfo.productId}/cover/${cutInfo.index}.png`;
                  return (
                    <div key={index} className={owned ? "" : "brightness-75"}>
                      {owned ? (
                        <Link
                          to={`viewer`}
                          state={{
                            productId: cutInfo.productId,
                            cutId: cutInfo.index,
                          }}
                          onClick={() => {
                            const eventName = `${cutInfo.displayName}/${cutInfo.productId}`;
                            logEvent(analytics, eventName);
                          }}
                        >
                          <Img
                            imgPath={imgPath}
                            alt="商品画像"
                            className="w-full shadow-md"
                          />
                        </Link>
                      ) : (
                        <Img
                          imgPath={imgPath}
                          alt="商品画像"
                          className="w-full"
                        />
                      )}
                      <Space height={16} />
                      <p>{cutInfo.displayName}</p>
                    </div>
                  );
                })}
              </div>
              <Space height={64} />
              <Link to="/collection">
                <BaseButton
                  title="コレクションへ"
                  backgroundColor="green"
                  textColor="#000"
                />
              </Link>
            </>
          ) : (
            <>
              <Space height={64} />
              <p>コレクションは空です。</p>
              <Space height={64} />
            </>
          )
        ) : null}
      </>
      <Space height={48} />
      <Share
        url={`https://gengakuji.com/${productData?.productId || ""}`}
        text={`${productData?.productName || ""}を購入しました！`}
        hashtags={
          animeData && animeData.hashtags
            ? [...animeData.hashtags, "原画くじ"]
            : ["原画くじ"]
        }
      />
      <Space height={48} />
    </>
  );
});

export default Collection;
