import { AnimeType, BoxType, ProductType } from "@gengakuji/common";
import { useAtom } from "jotai";
import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { isBuyBoxModalOpenAtom, isResetBoxModalOpenAtom } from "../atoms";
import { calculateTimeLeft } from "../utils/calculateTimeLeft";
import { formatDate } from "../utils/index";

import { BaseButton } from "./Button/BaseButton";
import { DisabledButton } from "./Button/DisabledButton";
import { YubiButton } from "./Button/YubiButton";
import { Fukidashi } from "./Fukidashi";
import { XShareButton } from "./Share";

interface Props {
  user: any;
  productData: ProductType | null;
  animeData: AnimeType | null;
  start: Date;
  end: Date;
  firstBuy: boolean;
  handleBuyKuji: (drawNumber: number) => void;
  box: BoxType | null;
  isLogin: boolean;
}

const now = new Date();

export const FixedBottom = memo(
  ({
    user,
    productData,
    animeData,
    start,
    end,
    firstBuy,
    handleBuyKuji,
    box,
    isLogin,
  }: Props) => {
    // 1回、10回購入用
    const [isLoadingOne, setIsLoadingOne] = useState(false);
    const [isLoadingTen, setIsLoadingTen] = useState(false);

    const [, setIsResetBoxModalOpenState] = useAtom(isResetBoxModalOpenAtom);
    const [, setIsBuyBoxModalOpenState] = useAtom(isBuyBoxModalOpenAtom);

    // 「全部引く」用ローディング管理を追加（Boxが空ではない場合）
    const [isLoadingAll, setIsLoadingAll] = useState(false);

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(start, end));
    const formattedStart = formatDate(start);

    // GoogleAnalytics用のイベント名作成
    const baseEventName = window.location.pathname + window.location.search;
    const eventNameSuffix = firstBuy ? "【初回】" : "";
    const eventName1 = `${eventNameSuffix}くじを1回引く${baseEventName}`;
    const eventName10 = `${eventNameSuffix}くじを10回引く${baseEventName}`;

    // カウントダウン更新
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(start, end));
      }, 1000);
      return () => clearInterval(timer);
    }, [start, end]);

    // 通常くじ購入
    const handleOnClick = async (drawNumber: number) => {
      const setIsLoading = drawNumber === 1 ? setIsLoadingOne : setIsLoadingTen;
      try {
        setIsLoading(true);
        await handleBuyKuji(drawNumber);
      } catch (error) {
        console.error("くじ購入に失敗しました", error);
      } finally {
        setIsLoading(false);
      }
    };

    // 「全部引く」: モーダル表示せず、直接チェックアウト処理へ
    const handleBoxAll = async () => {
      if (!box) return;
      setIsLoadingAll(true);
      try {
        await handleBuyKuji(box.leftCount); // これで即チェックアウトへ
      } catch (error) {
        console.error("全部引くに失敗しました", error);
      } finally {
        setIsLoadingAll(false);
      }
    };

    // productData がない場合は表示しない
    if (!productData) {
      return null;
    }

    return (
      <div
        className={`fixed ${
          isLogin ? "bottom-16" : "bottom-4"
        } z-[999] flex w-full max-w-[calc(100vw-32px)] justify-center min-[768px]:max-w-[calc(768px-32px)]`}
      >
        {/* 販売前 */}
        {now < start && (
          <>
            <Fukidashi>
              <span className="text-xs">販売開始まで</span>
              <p>
                {timeLeft?.daysCount}
                <span className="mx-1 text-xs">日</span>
                {timeLeft?.hoursCount}
                <span className="mx-1 text-xs">時間</span>
                {timeLeft?.minutesCount}
                <span className="mx-1 text-xs">分</span>
                {timeLeft?.secondsCount}
                <span className="mx-1 text-xs">秒</span>
              </p>
            </Fukidashi>
            {user ? (
              <XShareButton
                url={`https://gengakuji.com/${productData.productId || ""}`}
                text={`${
                  productData.productName ?? ""
                }に事前登録しました！ 【${formattedStart}】より販売開始！`}
                hashtags={
                  animeData?.hashtags
                    ? [...animeData.hashtags, "原画くじ"]
                    : ["原画くじ"]
                }
                related={["gengakuji"]}
                via="gengakuji"
              >
                <BaseButton title="Xにシェア" />
              </XShareButton>
            ) : (
              <Link
                to={`/register?from=${productData.productId}`}
                className="flex-1"
              >
                <BaseButton title="事前登録する" />
              </Link>
            )}
          </>
        )}
        {/* 販売中 */}
        {now >= start && now <= end && (
          <>
            {(firstBuy || !user) &&
              productData &&
              productData.initialDiscountRate > 0 && (
                <Fukidashi>
                  <p>初回限定{productData.initialDiscountRate}%OFF！</p>
                </Fukidashi>
              )}
            {/* 「確率タイプ」で「被りなし」なら10連で被りなし表記 */}
            {(firstBuy || !user) &&
              !productData.box?.isBox &&
              productData.isDuplicate === false && (
                <Fukidashi>
                  <p>10連で被りなし！</p>
                </Fukidashi>
              )}
            {user ? (
              <>
                {/* 販売終了までのカウントダウン */}
                {timeLeft && (
                  <Fukidashi>
                    <span className="text-xs">販売終了まで</span>
                    <p>
                      {timeLeft.daysCount}
                      <span className="mx-1 text-xs">日</span>
                      {timeLeft.hoursCount}
                      <span className="mx-1 text-xs">時間</span>
                      {timeLeft.minutesCount}
                      <span className="mx-1 text-xs">分</span>
                      {timeLeft.secondsCount}
                      <span className="mx-1 text-xs">秒</span>
                    </p>
                  </Fukidashi>
                )}

                {/* Box モード */}
                {productData.box?.isBox ? (
                  box?.leftCount === 0 ? (
                    // Boxが空
                    <div className="flex-1">
                      <BaseButton
                        onClick={() => {
                          setIsResetBoxModalOpenState(true);
                        }}
                        title="リセットしてくじを引く"
                      />
                    </div>
                  ) : (
                    // Boxに残りがある
                    <div className="flex w-full justify-center">
                      <div className="mr-1 flex-1">
                        <BaseButton
                          onClick={() => {
                            setIsBuyBoxModalOpenState(true);
                          }}
                          title="引く"
                        />
                      </div>
                      <div className="ml-1 flex-1">
                        <YubiButton
                          onClick={handleBoxAll} // ← 直接buyKujiを呼び出し
                          title="全部引く"
                          isLoading={isLoadingAll} // ローディング表示
                        />
                      </div>
                    </div>
                  )
                ) : (
                  // 通常モードの場合（確率ガチャ）
                  <div className="flex w-full justify-center">
                    <div className="mr-1 flex-1">
                      <BaseButton
                        onClick={() => handleOnClick(1)}
                        title="1回引く"
                        isLoading={isLoadingOne}
                        gaEvent={eventName1}
                      />
                    </div>
                    <div className="ml-1 flex-1">
                      <YubiButton
                        onClick={() => handleOnClick(10)}
                        title="10連を引く"
                        isLoading={isLoadingTen}
                        gaEvent={eventName10}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Link
                to={`/register?from=${productData.productId}`}
                className="flex-1"
              >
                <BaseButton title="登録・ログインしてくじを引く" />
              </Link>
            )}
          </>
        )}
        {/* 販売終了 */}
        {now > end && (
          <>
            <DisabledButton title="販売終了" />
          </>
        )}
      </div>
    );
  }
);
