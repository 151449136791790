import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  connectAuthEmulator,
} from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Firebase アプリを初期化
const firebaseApp = initializeApp(firebaseConfig);

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const googleProvider = new GoogleAuthProvider();
const analytics = getAnalytics(firebaseApp);

// ローカル環境の場合はエミュレーター接続
if (process.env.REACT_APP_ENV === "local") {
  // Auth Emulator (デフォルト: 9099)
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  // Firestore Emulator (デフォルト: 8080)
  connectFirestoreEmulator(db, "localhost", 8080);
  // Storage Emulator (デフォルト: 9199)
  connectStorageEmulator(storage, "localhost", 9199);
}

export { firebaseApp, auth, db, storage, googleProvider, analytics };
