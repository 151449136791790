import { Timestamp } from "firebase/firestore";

export const SALE_STATUS = {
  BEFORE_SALE: 0,
  NOW_ON_SALE: 1,
  AFTER_SALE: 2,
} as const;

export type SaleStatus = 0 | 1 | 2;

export type ProductTranslationsType = {
  //基本
  productName: string;
  kujiTitle: string;
  //日時
  scheduledShippingAt: string[];
  //商品内容
  productTypes: {
    SType?: string[];
    NormalType?: string[];
  };
  //Wチャンス
  wchancePrize: string;
  wchanceScheduledShippingAt: string;
};

export type ProductCommonType = {
  //基本
  productId: string;
  animeId: string;
  password: string;
  count: number;
  //対応する国
  availableCountries: string[];
  countryPrices: {
    [countryCode: string]: {
      currency: string;
      amount: number;
      postage: number;
    };
  };
  countryPaymentsMethod: {
    [countryCode: string]: string[];
  };
  //対応する言語
  availableLanguages: string[];
  translations: {
    [language: string]: ProductTranslationsType;
  };
  //Box型
  box: {
    isBox: boolean;
    isAllDrawOpen?: boolean;
    count: {
      normal: number;
      s: number;
    };
  };
  //確率型
  rate: {
    SType: number;
    NormalType: number;
  };
  initialDiscountRate: number;
  isDuplicate: boolean;
  isAdditionalShippingFeeRequired?: boolean;
  //日時
  open: Timestamp;
  start: Timestamp;
  end: Timestamp;
  shippingRequestDeadline: Timestamp[];
  //Wチャンス
  wchance: {
    isOpen: boolean;
    applyDeadline?: Timestamp;
    isWon?: boolean;
  };
  //任意
  productSlides?: string[];
  kujiVideo?: {
    S: string;
    Normal: string;
  };
};

// ProductTypeは言語別のデータを上書きする前提で運用する
export type ProductType = ProductCommonType & ProductTranslationsType;
