import {
  AnimeType,
  ProductType,
  CutInfoType,
  FirestoreDocument,
  CollectionType,
  BoxType,
} from "@gengakuji/common";
import { useAtom } from "jotai";
import { memo, useEffect, useState, lazy, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useParams, useNavigate } from "react-router-dom";

import {
  isBuyBoxModalOpenAtom,
  isResetBoxLoadingAtom,
  isResetBoxModalOpenAtom,
  languageAtom,
} from "../atoms";
import { AllDraw } from "../components/AllDraw";
import { BaseWhiteBg } from "../components/BaseWhiteBg";
import { BgH2 } from "../components/BgH2";
import { BaseButton } from "../components/Button/BaseButton";
import { FixedBottom } from "../components/FixedBottom";
import { Img } from "../components/Img";
import { Loading } from "../components/Loading";
import { BuyBoxModal } from "../components/Modal/BuyBoxModal";
import { ResetBoxModal } from "../components/Modal/ResetBoxModal";
import { Space } from "../components/Space";
import { ProductTable } from "../components/Table/ProductTable";
import {
  getTypedCutInfo,
  getProduct,
  getCollection,
  getAnime,
} from "../firebase/index";
import { getUser } from "../firebase/users";
import { useAuthUser } from "../hooks/useAuth";
import { fetchBoxContent } from "../utils/fetchBoxContent";
import { buyKuji, formatDate } from "../utils/index";
import { resetUserBox } from "../utils/resetBox";

import NotFound from "./NotFound";

const Prize = lazy(() =>
  import("../components/Prize").then((module) => ({ default: module.Prize }))
);
const Slide = lazy(() =>
  import("../components/Slide").then((module) => ({ default: module.Slide }))
);
const How = lazy(() =>
  import("../components/How").then((module) => ({ default: module.How }))
);
const Share = lazy(() =>
  import("../components/Share").then((module) => ({ default: module.Share }))
);
const PasswordForm = lazy(() =>
  import("../components/PasswordForm").then((module) => ({
    default: module.PasswordForm,
  }))
);

const SkeletonLoader = ({ height = "100px", className = "" }) => (
  <div
    className={`animate-pulse bg-gray rounded ${className}`}
    style={{ height }}
  ></div>
);

const Product = memo(() => {
  const [language] = useAtom(languageAtom);

  // ロード状態
  const [isLoading, setIsLoading] = useState(true);

  // Box購入モーダル関連
  const [isBuyBoxModalOpenState, setIsBuyBoxModalOpenState] = useAtom(
    isBuyBoxModalOpenAtom
  );

  // Boxリセットモーダル関連
  const [isResetBoxModalOpenState, setIsResetBoxModalOpenState] = useAtom(
    isResetBoxModalOpenAtom
  );
  const [isResetBoxLoadingState, setIsResetBoxLoadingState] = useAtom(
    isResetBoxLoadingAtom
  );

  // 取得データ関連
  const [validProductId, setValidProductId] = useState<boolean>(true);
  const [firstBuy, setFirstBuy] = useState(false);
  const [productData, setProductData] = useState<ProductType | null>(null);
  const [animeData, setAnimeData] = useState<AnimeType | null>(null);
  const [collections, setCollections] = useState<
    FirestoreDocument<CollectionType>[]
  >([]);
  const [sCutInfo, setSCutInfo] = useState<CutInfoType[] | null>(null);
  const [normalCutInfo, setNormalCutIndo] = useState<CutInfoType[] | null>(
    null
  );
  const [box, setBox] = useState<BoxType | null>(null);

  const { productId } = useParams();
  const navigate = useNavigate();

  // 日付
  const now = new Date();
  const start = productData ? new Date(productData.start.toDate()) : now;
  const end = productData ? new Date(productData.end.toDate()) : now;

  const formattedStart = formatDate(start);
  const formattedEnd = formatDate(end);

  //情報解禁前、監修用にパスワード付きのProductページを表示する
  const [isOpen, setIsOpen] = useState(false);
  const [isCorrectPassword, setIsCorrectPassword] = useState(false);

  //ログイン判定
  const user = useAuthUser();
  const [isLogin, setIsLogin] = useState(false);

  //ログイン判定
  useEffect(() => {
    setIsLogin(!!user?.uid);
  }, [user]);

  // ▼▼▼ 1. リセット処理 ▼▼▼
  const handleResetBox = async () => {
    setIsResetBoxLoadingState(true);
    try {
      if (!productData?.productId) return;
      const newBox = await resetUserBox(productData.productId);
      setBox(newBox);
    } catch (error) {
      console.error("Boxリセットに失敗", error);
    } finally {
      setIsResetBoxLoadingState(false);
      setIsResetBoxModalOpenState(false);
      // 必要に応じて、リセット直後に購入モーダルを開くなら下記を呼ぶ
      setIsBuyBoxModalOpenState(true);
    }
  };

  // ▼▼▼ 3. くじ購入（従来どおり） ▼▼▼
  const handleBuyKuji = async (drawNumber: number) => {
    await buyKuji(drawNumber, productData, language);
  };

  //productData取得
  useEffect(() => {
    const fetchData = async () => {
      if (productId) {
        const product = await getProduct(productId);

        if (!product) {
          setValidProductId(false);
          setIsLoading(false);
          navigate("/404");
          return;
        }
        setProductData(product);

        // animeデータ取得
        const anime = await getAnime(productId);
        setAnimeData(anime);

        // Boxデータ取得
        if (product.box?.isBox) {
          const boxData = await fetchBoxContent(productId);
          setBox(boxData);
        }

        // 情報解禁日時
        const now = new Date();
        const open = new Date(product.open.toDate());
        setIsOpen(now > open); // 情報解禁判定
      }

      // S賞と通常賞のカット情報の取得
      const sData = productId ? await getTypedCutInfo(productId, "S") : null;
      setSCutInfo(sData);
      const normalData = productId
        ? await getTypedCutInfo(productId, "Normal")
        : null;
      setNormalCutIndo(normalData);

      setIsLoading(false);
    };

    fetchData();
  }, [productId, navigate, language]);

  // 初回購入判定
  useEffect(() => {
    const checkFirstBuy = async () => {
      if (user && productId) {
        const userData = await getUser(user.uid);
        if (userData && !userData.boughtProductIds.includes(productId)) {
          setFirstBuy(true);
        }
        const collectionsData = await getCollection(user.uid, productId);
        setCollections(collectionsData);
      }
    };
    checkFirstBuy();
  }, [user, productId]);

  // 画像のpreload
  useEffect(() => {
    if (productData) {
      const criticalImages = [];

      criticalImages.push(`${productData.productId}/ogp.png`);

      if (
        productData.productSlides &&
        Object.keys(productData.productSlides).length > 0
      ) {
        const firstSlide = Object.values(productData.productSlides)[0];
        if (firstSlide) {
          criticalImages.push(firstSlide);
        }
      }
      criticalImages.forEach((imgPath) => {
        const img = new Image();
        img.src = `https://storage.googleapis.com/${process.env.REACT_APP_STORAGE_BUCKET}/${imgPath}`;
      });
    }
  }, [productData]);

  if (isLoading) {
    return (
      <>
        <Space height={64} />
        <Loading />
        <Space height={64} />
      </>
    );
  }

  if (!isOpen && !isCorrectPassword) {
    return (
      <PasswordForm
        productId={productId}
        correctPassword={productData?.password}
        onPasswordCorrect={() => setIsCorrectPassword(true)}
      />
    );
  }

  if (!validProductId) {
    return <NotFound />;
  }

  return (
    <>
      <Helmet>
        <title>{(productData && productData.productName) || ""}</title>
        <link rel="preconnect" href="https://storage.googleapis.com" />
        <link rel="dns-prefetch" href="https://storage.googleapis.com" />
      </Helmet>

      {/* モーダル表示部分 */}
      <>
        {isResetBoxModalOpenState && (
          <ResetBoxModal
            onConfirm={handleResetBox}
            isLoading={isResetBoxLoadingState}
            box={box}
          />
        )}
        {isBuyBoxModalOpenState && (
          <BuyBoxModal product={productData} leftCount={box?.leftCount ?? 1} />
        )}
      </>

      <Space height={16} />
      <>
        <Img
          imgPath={`${productId}/ogp.png`}
          alt={productData?.productName || "商品画像"}
          className="mx-auto border-white border-2"
          priority={true}
          sizes="(max-width: 768px) 100vw, 768px"
        />
        <Space height={16} />
        <h1>「{animeData?.title || ""}」</h1>
        <h2>{productData?.kujiTitle || ""}</h2>
        <Space height={16} />
        <Link to="sample">
          <BaseButton
            title="動くデジタル原画のサンプルを見る"
            backgroundColor="green"
            textColor="#000"
          />
        </Link>
        <Space height={16} />
        {/* wchance.isOpenがtrueの場合のみ表示 */}
        {productData?.wchance.isOpen && (
          <>
            <Link to="wchance">
              <BaseButton
                title="Wチャンスに応募する"
                backgroundColor="blue"
                textColor="#000"
              />
            </Link>
            <Space height={16} />
          </>
        )}

        <Suspense fallback={<SkeletonLoader height="200px" className="mt-4" />}>
          <ProductTable
            productData={productData}
            animeData={animeData}
            firstBuy={firstBuy}
            user={user}
            formattedStart={formattedStart}
            formattedEnd={formattedEnd}
          />
        </Suspense>

        <Space height={40} />

        <Suspense fallback={<SkeletonLoader height="300px" />}>
          <Slide productData={productData} productId={productId ?? ""} />
        </Suspense>

        <Space height={40} />

        {productData?.box?.isBox && (
          <>
            <h2>
              残り {box?.leftCount} / {box?.allCount}
            </h2>
            <Space height={16} />

            {/* くじが引かれている場合のみリセットボタンを表示 */}
            {box && box.leftCount < box.allCount && (
              <>
                <BaseButton
                  onClick={() => setIsResetBoxModalOpenState(true)}
                  title="ボックスをリセットする"
                  disabled={false}
                />
                <Space height={16} />
              </>
            )}
          </>
        )}

        <Suspense fallback={<SkeletonLoader height="200px" />}>
          <Prize
            title="S賞"
            count={sCutInfo ? sCutInfo.length : 0}
            rate={productData ? productData.rate.SType : 6}
            alt="S賞画像"
            className="mx-auto grid grid-cols-1 gap-4"
            cutInfo={sCutInfo}
            productData={productData}
            animeData={animeData}
            collections={collections}
            box={box}
          />
        </Suspense>

        <Space height={48} />

        <Suspense fallback={<SkeletonLoader height="300px" />}>
          <Prize
            title="通常賞"
            count={normalCutInfo ? normalCutInfo.length : 0}
            rate={productData ? productData.rate.NormalType : 94}
            alt="通常賞画像"
            className="mx-auto grid grid-cols-2 gap-4"
            cutInfo={normalCutInfo}
            productData={productData}
            animeData={animeData}
            collections={collections}
            box={box}
          />
        </Suspense>

        {productId && productId.includes("sorairo_utility") && (
          <Suspense fallback={<SkeletonLoader height="100px" />}>
            <Space height={48} />
            <AllDraw productId={productId ?? ""} />
          </Suspense>
        )}

        <Space height={48} />

        <Suspense fallback={<SkeletonLoader height="100px" />}>
          <How />
        </Suspense>

        <Space height={48} />
        <Suspense fallback={<SkeletonLoader height="100px" />}>
          <Share
            url={`https://gengakuji.com/${productData?.productId || ""}`}
            text={
              // 販売前
              now < start
                ? `${
                    productData?.productName ?? ""
                  }事前登録受付中！ 【${formattedStart}】より販売開始！`
                : // 販売中
                now <= end
                ? `${productData?.productName ?? ""}販売中！${
                    productData?.initialDiscountRate &&
                    productData.initialDiscountRate > 0
                      ? `【初回限定${productData.initialDiscountRate}%OFF！】`
                      : ""
                  }`
                : // 販売終了
                  `【販売終了】${productData?.productName ?? ""}`
            }
            hashtags={
              animeData?.hashtags
                ? [...animeData.hashtags, "原画くじ"]
                : ["原画くじ"]
            }
          />
        </Suspense>

        <FixedBottom
          user={user}
          productData={productData}
          animeData={animeData}
          start={start}
          end={end}
          firstBuy={firstBuy}
          handleBuyKuji={handleBuyKuji}
          box={box}
          isLogin={isLogin}
        />
      </>
      <Space height={48} />
    </>
  );
});

export default Product;
